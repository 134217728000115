import React from "react";
import {
  LazyLoadImage,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const LazyImage = ({ src, alt, placeholder, className, scrollPosition }) => {
  return (
    <LazyLoadImage
      src={src}
      alt={alt}
      placeholderSrc={placeholder}
      effect="blur"
      scrollPosition={scrollPosition}
      width="100%"
      height="auto"
      className={className}
    />
  );
};

// Wrap the component with `trackWindowScroll` to enable scroll tracking
export default trackWindowScroll(LazyImage);
