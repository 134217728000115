import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { GoArrowRight } from "react-icons/go";
import ScrollToTop from "../components/ScrollToTop";
import { FaAngleRight } from "react-icons/fa6";
import ClipLoader from "react-spinners/ClipLoader"; // Import the spinner
import sanityClient from "../client";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const Blogs = () => {
  const { categoryName } = useParams(); // Get category name from URL params
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate(); // React Router v6

  useEffect(() => {
    const fetchCategoryPosts = async () => {
      try {
        const data = await sanityClient.fetch(
          `*[_type == "post" && $categoryName in categories[]->title && websites[references(*[_type == "website" && name match "Axillio*"]._id)]] | order(publishedAt desc){
            title,
            slug,
            author->{name, image, bio},
            publishedAt,
            websites[]->{name},
            mainImage{
              asset->{
                _id,
                url
              }
            }
          }`,
          { categoryName } // Dynamically pass categoryName to the query
        );
        setPosts(data);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetchCategoryPosts();
  }, [categoryName]); // Re-fetch posts if categoryName changes

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-[#e6e2dd]">
        <ClipLoader size={50} color={"#8b8986"} loading={loading} />
      </div>
    );
  }

  if (posts.length === 0) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-[#e6e2dd] font-gilroyBold text-2xl">
        No posts found for {categoryName} .
      </div>
    );
  }

  if (posts.length === 0) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-[#e6e2dd] font-gilroyBold text-2xl">
        No posts found for {categoryName} .
      </div>
    );
  }

  // Split the posts array into alternating groups of 2 and 3
  const getPostRows = (posts) => {
    let rows = [];
    for (let i = 0; i < posts.length; ) {
      rows.push(posts.slice(i, i + 2)); // First row of 2 posts
      i += 2;
      rows.push(posts.slice(i, i + 3)); // Second row of 3 posts
      i += 3;
    }
    return rows;
  };

  const postRows = getPostRows(posts);

  const handlePostClick = (post) => {
    // Navigate to the next page and pass the post object as state
    navigate(`/${post}`, { state: { categoryName } });
  };

  return (
    <>
      <Helmet>
        <title>Axillio Blog - Latest News and AI Innovations</title>
        <meta
          name="description"
          content="Stay updated with the latest AI-driven product launches, technology trends, and innovations from Axillio."
        />
      </Helmet>
      <ScrollToTop />
      <div className="bg-[#e6e2dd] pt-12 min-h-screen font-gilroyRegular">
        {/* navbar */}
        <div className="flex flex-row items-center justify-between px-4 md:px-16 lg:px-32 xl:px-48">
          <Link to={"/"}>
            <img
              alt="axillio-logo"
              src="/images/axillio-logo.png"
              className="w-full h-auto"
            />
          </Link>
        </div>

        <div className="flex flex-row items-center px-4 pt-8 space-x-2 md:px-16 lg:px-32 xl:px-48 md:pt-16">
          <Link
            to="/categories"
            className="text-[#303030] text-base md:text-xl font-normal cursor-pointer no-underline"
          >
            <h1 className="text-[#303030] text-base md:text-xl font-normal">
              Blogs
            </h1>
          </Link>
          <FaAngleRight className="text-[#303030] text-base md:text-xl font-semibold" />
          <h1 className="text-[#303030] text-base md:text-xl text-center font-semibold">
            {categoryName
              .split("-")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ")}
          </h1>
        </div>

        {/* section one */}
        <div className="flex flex-col items-center justify-center px-4 pt-16 space-y-20 md:px-16 lg:px-32 xl:px-48 md:pt-40 lg:pt-56 md:space-y-40">
          <div className="flex flex-col items-center justify-center space-y-4 md:space-y-6">
            <h1 className="text-[#303030] text-lg md:text-xl font-semibold">
              Blogs
            </h1>
            <h1 className="text-[#303030] text-3xl md:text-5xl text-center font-gilroyBold">
              {categoryName
                .split("-")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ")}
            </h1>
          </div>

          <div className="flex flex-col items-center justify-center space-y-8 md:space-y-10">
            {postRows.map((row, rowIndex) => (
              <div
                key={rowIndex}
                className={`flex flex-col md:flex-row ${
                  row.length === 3 ? "border-y-2 border-[#8b8986] py-6" : ""
                }`}
              >
                {row.map((post, postIndex) => (
                  <div
                    key={postIndex}
                    to={`/${post.slug.current}`}
                    onClick={() => handlePostClick(`${post.slug.current}`)}
                    className={`flex flex-col space-y-3 ${
                      row.length === 2
                        ? "w-full md:w-[48%] lg:w-[68vh] hover:cursor-pointer"
                        : "w-full md:w-[32%] lg:w-[45vh] hover:cursor-pointer"
                    } ${
                      postIndex === 0 && row.length === 2
                        ? "md:border-r-2 border-[#8b8986] md:pr-3"
                        : ""
                    }${postIndex === 1 && row.length === 2 ? "md:pl-3" : ""} ${
                      postIndex === 1 && row.length === 3
                        ? "md:border-x-2 border-[#8b8986] md:px-3"
                        : ""
                    }  
               ${postIndex === 0 && row.length === 3 ? " md:pr-3" : ""}
                ${postIndex === 2 && row.length === 3 ? " md:pl-3" : ""}`}
                  >
                    <img
                      alt={post.title}
                      src={
                        post.mainImage?.asset?.url ||
                        "/images/default-image.png"
                      }
                      className={`${
                        row.length === 2
                          ? "w-full md:w-[68vh]"
                          : "w-full md:w-[45vh]"
                      } h-auto`}
                    />
                    <div className="flex flex-col px-2 py-8 pt-2 space-y-3 md:py-0">
                      <h1 className="text-sm md:text-md text-[#94928f]">
                        {new Date(post.publishedAt).toLocaleDateString()}
                      </h1>
                      <h1 className="text-lg md:text-xl text-[#303030] font-bold">
                        {post.title}
                      </h1>
                      {/* <h1>{post.excerpt || "No excerpt available..."}</h1> */}
                      <div className="flex flex-row items-center pt-0 space-x-2 md:pt-3 group">
                        <h1 className="text-[#303030] text-md md:text-xl font-bold transition-all duration-300 group-hover:text-[#505050] group-hover:translate-x-1">
                          Read more
                        </h1>
                        <GoArrowRight className="text-[#303030] text-lg md:text-2xl font-bold transition-all duration-300 group-hover:text-[#505050] group-hover:translate-x-1" />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>

          <div className="flex flex-col items-center justify-center space-y-6"></div>
        </div>
        {/* footer */}
        <div className="bg-[#1a1a1a] flex flex-col space-y-16 md:space-y-28 pb-12 pt-12 md:pt-24 mt-20 md:mt-32 px-8 md:px-16 lg:px-24 xl:px-48">
          <div className="flex flex-col justify-between md:flex-row">
            <div className="flex flex-col space-y-6">
              <h1 className="text-[#e6e2dd] text-lg md:text-2xl font-semibold">
                AXILLIO
              </h1>
              <div className="flex flex-col">
                <h1 className="text-sm md:text-md text-[#d6d2cd]">
                  San Francisco
                </h1>
                <h1 className="text-sm md:text-md text-[#d6d2cd]">
                  Delaware UAE
                </h1>
              </div>
              <h1 className="text-sm md:text-md text-[#d6d2cd]">
                example@axillio.com
              </h1>
            </div>
            <div className="flex flex-col space-y-4">
              <h1 className="text-sm md:text-md text-[#d6d2cd]">Portfolio</h1>
              <h1 className="text-sm md:text-md text-[#d6d2cd]">Services</h1>
              <h1 className="text-sm md:text-md text-[#d6d2cd]">About</h1>
              <Link
                to={"/categories"}
                className="text-sm md:text-md text-[#d6d2cd]"
              >
                Blogs
              </Link>
            </div>
            <div className="flex flex-col space-y-6">
              <div className="flex flex-row items-center pt-8 space-x-1 md:pt-0 hover:cursor-pointer ">
                <h1 className="text-[#e6e2dd] text-lg md:text-xl font-semibold ">
                  Start a Project
                </h1>
                <GoArrowRight className="text-[#e6e2dd] text-lg md:text-xl" />
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center justify-between space-y-8 md:space-y-12">
            <h1 className="text-sm md:text-md text-[#d6d2cd]">Follow us on:</h1>
            <div className="flex flex-row items-center justify-center space-x-4 md:space-x-8">
              <a
                href="https://www.behance.net/axillio"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt="behance"
                  src="./images/behance_logo.png"
                  className="w-8 h-auto md:w-12 lg:w-full"
                />
              </a>
              <a
                href="https://x.com/AliMukhtar081"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt="x"
                  src="./images/x_logo.png"
                  className="w-8 h-auto md:w-12 lg:w-full"
                />
              </a>
              <a
                href="https://dribbble.com/axillio"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt="football"
                  src="./images/footbal-logo.png"
                  className="w-8 h-auto md:w-12 lg:w-full"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/axillio/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt="linkedin"
                  src="./images/linkedin_symbol.png"
                  className="w-8 h-auto md:w-12 lg:w-full"
                />
              </a>
              <a
                href="https://www.instagram.com/axillioofficial/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt="insta"
                  src="./images/insta-logo.png"
                  className="w-8 h-auto md:w-12 lg:w-full"
                />
              </a>
              <a
                href="https://www.youtube.com/@Axillio"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt="youtube"
                  src="./images/youtube-logo.png"
                  className="w-8 h-auto md:w-12 lg:w-full"
                />
              </a>
            </div>

            <h1 className="text-sm md:text-md text-[#d6d2cd]">
              © 2024 Axillio. All Rights Reserved
            </h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default Blogs;
