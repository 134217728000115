import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Categories from "./pages/Categories";
import Blogs from "./pages/Blogs";
import Blog from "./pages/Blog";
import Roam from "./pages/potfolio/Roam";
import Athlantix from "./pages/potfolio/Athlantix";
import Velara from "./pages/potfolio/Velara";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import Proposal from "./pages/Proposal";
import ProposalForm from "./pages/ProposalForm";

function App() {
  return (
    <>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/proposal/:title/:id" element={<Proposal />} />
          <Route path="/proposal-form" element={<ProposalForm />} />
          <Route path="/categories" element={<Categories />} />
          <Route path="/blogs/:categoryName" element={<Blogs />} />
          <Route path="/:blogName" element={<Blog />} />
          <Route path="/roam" element={<Roam />} />
          <Route path="/athlantix" element={<Athlantix />} />
          <Route path="/velara" element={<Velara />} />
        </Routes>
      </div>
    </>
  );
}

export default App;
