// import { Link } from "react-router-dom";
// import React, { useEffect, useState } from "react";
// import ScrollToTop from "../components/ScrollToTop";
// import { useDropzone } from "react-dropzone";
// import { useCallback } from "react";
// import { motion } from "framer-motion";
// import { Button } from "@mantine/core";

// const ProposalForm = () => {
//   //-------------------------option select-----------------------//
//   const [selectedOption, setSelectedOption] = useState(null);

//   const handleSelect = (option) => {
//     setSelectedOption(option);
//   };
//   //----------------------projects select--------------------//

//   const [selectedProjects, setSelectedProjects] = useState([]);

//   const handleProjectSelect = (projectName) => {
//     // Toggle selection of projects
//     setSelectedProjects((prevSelected) =>
//       prevSelected.includes(projectName)
//         ? prevSelected.filter((name) => name !== projectName)
//         : [...prevSelected, projectName]
//     );
//   };

//   const isSelected = (projectName) => selectedProjects.includes(projectName);
//   //-----------------------dropzone-------------------------//
//   const [uploadedFiles, setUploadedFiles] = useState([]);

//   const onDrop = useCallback((acceptedFiles) => {
//     setUploadedFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
//   }, []);

//   const {
//     getRootProps: getRootPropsGeneral,
//     getInputProps: getInputPropsGeneral,
//     isDragActive: isDragActiveGeneral,
//   } = useDropzone({
//     onDrop,
//     accept: "application/pdf, image/*",
//   });

//   //-------------------------------Loom Videos-------------------------------//
//   const [uploadedLoomVideo, setUploadedLoomVideo] = useState([]);
//   const [loomLink, setLoomLink] = useState(""); // To handle Loom video links

//   const onDropLoom = useCallback((acceptedFiles) => {
//     setUploadedLoomVideo((prevFiles) => [...prevFiles, ...acceptedFiles]);
//   }, []);

//   const handleLoomLinkSubmit = () => {
//     if (loomLink) {
//       setUploadedLoomVideo((prevFiles) => [
//         ...prevFiles,
//         { name: loomLink, type: "link" },
//       ]);
//       setLoomLink(""); // Clear input after submission
//     }
//   };

//   const {
//     getRootProps: getRootPropsLoom,
//     getInputProps: getInputPropsLoom,
//     isDragActive: isDragActiveLoom,
//   } = useDropzone({
//     onDrop: onDropLoom,
//     accept: "video/*", // Accepting video files
//   });
//   //-------------------------------------------------------//
//   return (
//     <>
//       <ScrollToTop />
//       <div className="bg-[#e6e2dd] pt-12 min-h-screen font-gilroyRegular">
//         {/* navbar */}
//         <div className="flex flex-row items-center justify-between px-4 md:px-16 lg:px-32 xl:px-48">
//           <Link to={"/"}>
//             <img
//               alt="axillio-logo"
//               src="./images/axillio-logo.png"
//               className="w-full h-auto"
//             />
//           </Link>
//         </div>
//         {/* section one */}
//         <div className="flex flex-col items-center justify-center px-8 pt-20 space-y-10 md:px-16 lg:px-24 xl:px-48 md:pt-28 ">
//           <div className="flex flex-col items-center justify-center space-y-4 md:space-y-8 border-b border-[#8b8986] pb-8 w-full">
//             <h1 className="text-[#303030] text-4xl md:text-5xl text-center font-gilroyBold">
//               Proposal
//             </h1>
//           </div>
//           <div className="flex flex-col items-center justify-center w-full px-2 space-y-8 md:px-20">
//             {" "}
//             <div className="flex flex-col w-full pt-24 space-y-3 md:space-y-6 ">
//               <h1 className="text-[#676665] text-lg sm:text-xl md:text-2xl text-left font-semibold">
//                 Proposal Description
//               </h1>

//               <textarea
//                 placeholder="Enter here ..."
//                 className="border-2 border-[#c2bfbb] text-lg sm:text-xl md:text-2xl bg-[#e6e2dd] text-[#8b8986] p-2 w-full  resize-none min-h-[350px] max-h-[400px]"
//                 rows="8"
//               />
//             </div>
//             <div className="flex flex-col w-full pt-24 space-y-3 md:space-y-6 ">
//               <h1 className="text-[#676665] text-lg sm:text-xl md:text-2xl text-left font-semibold">
//                 Upload any Relevant Document{" "}
//               </h1>
//               <div
//                 {...getRootPropsGeneral()}
//                 className="flex items-center justify-center w-full border-2 border-[#c2bfbb] p-4 min-h-[250px] space-y-4 flex-col hover:cursor-pointer"
//               >
//                 <input {...getInputPropsGeneral()} />
//                 <img alt="upload" src="/images/upload.png" />
//                 <h1 className="text-[#303030] text-xl md:text-2xl font-semibold">
//                   {isDragActiveGeneral
//                     ? "Drop your document here..."
//                     : "Upload Document"}
//                 </h1>
//                 <h1 className="text-[#94928f] text-md md:text-lg font-semibold">
//                   (Drag & Drop or Upload from device)
//                 </h1>

//                 {/* Display uploaded files inside the same div */}
//                 <div className="w-full mt-4">
//                   {uploadedFiles.length > 0 && (
//                     <div className="flex flex-col w-full space-y-2">
//                       {uploadedFiles.map((file, index) => (
//                         <div
//                           key={index}
//                           className="flex items-center justify-between w-full p-2 border border-[#c2bfbb] rounded-lg"
//                         >
//                           {/* Display image preview if the file is an image */}
//                           {file.type.startsWith("image/") && (
//                             <img
//                               src={URL.createObjectURL(file)}
//                               alt="Preview"
//                               className="object-cover w-16 h-16 mr-4 rounded-md"
//                             />
//                           )}
//                           {/* File name */}
//                           <span className="text-gray-700 break-words text-md">
//                             {file.name}
//                           </span>
//                         </div>
//                       ))}
//                     </div>
//                   )}
//                 </div>
//               </div>
//             </div>
//             <div className="flex flex-col w-full pt-24 space-y-3 md:space-y-6">
//               <h1 className="text-[#676665] text-lg sm:text-xl md:text-2xl text-left font-semibold">
//                 Select one of the following:
//               </h1>
//               <div className="flex flex-row space-x-12">
//                 {/* Option 1: Design Only */}
//                 <motion.div
//                   whileHover={{ scale: 1.05 }}
//                   onClick={() => handleSelect("designOnly")}
//                   className={`cursor-pointer flex items-center justify-center w-full border-2 p-4 min-h-[250px] space-y-4 flex-col transition-all duration-300 ease-in-out ${
//                     selectedOption === "designOnly"
//                       ? "border-[#8b8986] scale-105"
//                       : "border-[#c2bfbb]"
//                   }`}
//                 >
//                   <img alt="curve" src="/images/curve.png" />
//                   <h1 className="text-[#303030] text-xl md:text-2xl font-semibold">
//                     Design Only
//                   </h1>
//                 </motion.div>

//                 {/* Option 2: Design and Development */}
//                 <motion.div
//                   whileHover={{ scale: 1.05 }}
//                   onClick={() => handleSelect("designAndDevelopment")}
//                   className={`cursor-pointer flex items-center justify-center w-full border-2 p-4 min-h-[250px] space-y-4 flex-col transition-all duration-300 ease-in-out ${
//                     selectedOption === "designAndDevelopment"
//                       ? "border-[#8b8986] scale-105"
//                       : "border-[#c2bfbb]"
//                   }`}
//                 >
//                   <img
//                     alt="app-development"
//                     src="/images/app-development.png"
//                   />
//                   <h1 className="text-[#303030] text-xl md:text-2xl font-semibold">
//                     Design and Development
//                   </h1>
//                 </motion.div>
//               </div>
//             </div>
//             <div className="flex flex-col w-full pt-24 space-y-3 md:space-y-6">
//               <h1 className="text-[#676665] text-lg sm:text-xl md:text-2xl text-left font-semibold">
//                 Select Relevant Projects:
//               </h1>
//               <div className="flex flex-col items-center justify-center w-full space-x-0 space-y-4 md:flex-row md:space-x-8 md:space-y-0">
//                 {/* Project 1 */}
//                 <motion.div
//                   whileHover={{ scale: 1.05 }}
//                   onClick={() => handleProjectSelect("Athlantix")}
//                   className={`border-2 p-6 h-auto w-full flex flex-col space-y-4 overflow-wrap break-words cursor-pointer transition-all duration-300 ease-in-out ${
//                     isSelected("Athlantix")
//                       ? "border-[#8b8986] scale-105"
//                       : "border-[#c2bfbb]"
//                   }`}
//                 >
//                   <img alt="blank" src="/images/blank.png" />
//                   <div className="flex flex-col pb-2 space-y-2">
//                     <div className="flex flex-row items-center space-x-2">
//                       <img alt="athlantix" src="/images/athlantix-small.png" />
//                       <h1 className="text-[#303030] text-md md:text-lg text-left font-semibold">
//                         Athlantix
//                       </h1>
//                     </div>
//                     <h1 className="text-[#676665] text-lg md:text-xl text-left">
//                       We improved customer engagement for Athlantix by
//                       implementing a multi-channel strategy and building a
//                       personalized recommendation engine.
//                     </h1>
//                   </div>
//                   <div className="flex flex-row items-center space-x-2">
//                     <h1 className="text-[#676665] text-sm md:text-md p-1 border border-[#c2bfbb]">
//                       Web Design
//                     </h1>
//                     <h1 className="text-[#676665] text-sm md:text-md p-1 border border-[#c2bfbb]">
//                       Landing page design
//                     </h1>
//                     <h1 className="text-[#676665] text-sm md:text-md p-1 border border-[#c2bfbb]">
//                       Branding
//                     </h1>
//                   </div>
//                 </motion.div>

//                 {/* Project 2 */}
//                 <motion.div
//                   whileHover={{ scale: 1.05 }}
//                   onClick={() => handleProjectSelect("Athlantix2")}
//                   className={`border-2 p-6 h-auto w-full flex flex-col space-y-4 overflow-wrap break-words cursor-pointer transition-all duration-300 ease-in-out ${
//                     isSelected("Athlantix2")
//                       ? "border-[#8b8986] scale-105"
//                       : "border-[#c2bfbb]"
//                   }`}
//                 >
//                   <img alt="blank" src="/images/blank.png" />
//                   <div className="flex flex-col pb-2 space-y-2">
//                     <div className="flex flex-row items-center space-x-2">
//                       <img alt="athlantix" src="/images/athlantix-small.png" />
//                       <h1 className="text-[#303030] text-md md:text-lg text-left font-semibold">
//                         Athlantix 2
//                       </h1>
//                     </div>
//                     <h1 className="text-[#676665] text-lg md:text-xl text-left">
//                       We improved customer engagement for Athlantix by
//                       implementing a multi-channel strategy and building a
//                       personalized recommendation engine.
//                     </h1>
//                   </div>
//                   <div className="flex flex-row items-center space-x-2">
//                     <h1 className="text-[#676665] text-sm md:text-md p-1 border border-[#c2bfbb]">
//                       Web Design
//                     </h1>
//                     <h1 className="text-[#676665] text-sm md:text-md p-1 border border-[#c2bfbb]">
//                       Landing page design
//                     </h1>
//                     <h1 className="text-[#676665] text-sm md:text-md p-1 border border-[#c2bfbb]">
//                       Branding
//                     </h1>
//                   </div>
//                 </motion.div>

//                 {/* Project 3 */}
//                 <motion.div
//                   whileHover={{ scale: 1.05 }}
//                   onClick={() => handleProjectSelect("Athlantix3")}
//                   className={`border-2 p-6 h-auto w-full flex flex-col space-y-4 overflow-wrap break-words cursor-pointer transition-all duration-300 ease-in-out ${
//                     isSelected("Athlantix3")
//                       ? "border-[#8b8986] scale-105"
//                       : "border-[#c2bfbb]"
//                   }`}
//                 >
//                   <img alt="blank" src="/images/blank.png" />
//                   <div className="flex flex-col pb-2 space-y-2">
//                     <div className="flex flex-row items-center space-x-2">
//                       <img alt="athlantix" src="/images/athlantix-small.png" />
//                       <h1 className="text-[#303030] text-md md:text-lg text-left font-semibold">
//                         Athlantix 3
//                       </h1>
//                     </div>
//                     <h1 className="text-[#676665] text-lg md:text-xl text-left">
//                       We improved customer engagement for Athlantix by
//                       implementing a multi-channel strategy and building a
//                       personalized recommendation engine.
//                     </h1>
//                   </div>
//                   <div className="flex flex-row items-center space-x-2">
//                     <h1 className="text-[#676665] text-sm md:text-md p-1 border border-[#c2bfbb]">
//                       Web Design
//                     </h1>
//                     <h1 className="text-[#676665] text-sm md:text-md p-1 border border-[#c2bfbb]">
//                       Landing page design
//                     </h1>
//                     <h1 className="text-[#676665] text-sm md:text-md p-1 border border-[#c2bfbb]">
//                       Branding
//                     </h1>
//                   </div>
//                 </motion.div>
//               </div>
//             </div>
//             <div className="flex items-center justify-center py-16 md:py-28 border-b border-[#8b8986] w-full">
//               <Button radius="sm" variant="filled" color="#303030" size="xl">
//                 Save & Upload Proposal{" "}
//               </Button>
//             </div>
//             <div className="flex flex-col w-full pt-24 space-y-3 md:space-y-6 ">
//               <h1 className="text-[#676665] text-lg sm:text-xl md:text-2xl text-left font-semibold">
//                 Upload Loom Video
//               </h1>
//               <div
//                 {...getRootPropsLoom()}
//                 className="flex items-center justify-center w-full border-2 border-[#c2bfbb] p-4 min-h-[500px] space-y-4 flex-col hover:cursor-pointer"
//               >
//                 <input {...getInputPropsLoom()} />
//                 <img alt="upload" src="/images/upload.png" />
//                 <h1 className="text-[#303030] text-xl md:text-2xl font-semibold">
//                   {isDragActiveLoom
//                     ? "Drop your video here..."
//                     : "Upload Video"}
//                 </h1>
//                 <h1 className="text-[#94928f] text-md md:text-lg font-semibold">
//                   (Drag & Drop or Upload from device)
//                 </h1>

//                 {/* Display uploaded files inside the same div */}
//                 <div className="w-full mt-4">
//                   {uploadedLoomVideo.length > 0 && (
//                     <div className="flex flex-col w-full space-y-2">
//                       {uploadedLoomVideo.map((file, index) => (
//                         <div
//                           key={index}
//                           className="flex items-center justify-between w-full p-2 border border-[#c2bfbb] rounded-lg"
//                         >
//                           {/* Display image preview if the file is an image */}
//                           {file.type.startsWith("image/") && (
//                             <img
//                               src={URL.createObjectURL(file)}
//                               alt="Preview"
//                               className="object-cover w-16 h-16 mr-4 rounded-md"
//                             />
//                           )}
//                           {/* File name */}
//                           <span className="text-gray-700 break-words text-md">
//                             {file.name}
//                           </span>
//                         </div>
//                       ))}
//                     </div>
//                   )}
//                 </div>
//               </div>
//             </div>
//             <div className="flex items-center justify-center py-16 md:py-28 ">
//               <Button radius="sm" variant="filled" color="#303030" size="xl">
//                 Upload Proposal{" "}
//               </Button>
//             </div>
//           </div>
//         </div>
//         {/* footer */}
//         <div className="bg-[#1a1a1a] flex flex-col space-y-16 md:space-y-28 pb-12 pt-12 md:pt-24 mt-32 md:mt-64 px-8 md:px-16 lg:px-32 xl:px-48">
//           <div className="flex flex-col items-center space-y-8 md:space-y-12">
//             <h1 className="text-sm md:text-md text-[#d6d2cd]">Follow us on:</h1>
//             <div className="flex flex-row items-center justify-center space-x-4 md:space-x-8">
//               <img
//                 alt="behance"
//                 src="./images/behance_logo.png"
//                 className="w-6 h-auto md:w-8 lg:w-full"
//               />
//               <img
//                 alt="x"
//                 src="./images/x_logo.png"
//                 className="w-6 h-auto md:w-8 lg:w-full"
//               />
//               <img
//                 alt="football"
//                 src="./images/footbal-logo.png"
//                 className="w-6 h-auto md:w-8 lg:w-full"
//               />
//               <img
//                 alt="linkedin"
//                 src="./images/linkedin_symbol.png"
//                 className="w-6 h-auto md:w-8 lg:w-full"
//               />
//               <img
//                 alt="insta"
//                 src="./images/insta-logo.png"
//                 className="w-6 h-auto md:w-8 lg:w-full"
//               />
//               <img
//                 alt="youtube"
//                 src="./images/youtube-logo.png"
//                 className="w-6 h-auto md:w-8 lg:w-full"
//               />
//             </div>
//             <h1 className="text-sm md:text-md text-[#d6d2cd]">
//               © 2024 Axillio. All Rights Reserved
//             </h1>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default ProposalForm;

import { Link } from "react-router-dom";
import React, { useState, useCallback } from "react";
import ScrollToTop from "../components/ScrollToTop";
import { useDropzone } from "react-dropzone";
import { motion } from "framer-motion";
import { Button } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader"; // Import the spinner

const ProposalForm = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false); // State to track loading
  const [proposalUrl, setProposalUrl] = useState(null);
  //-------------------------option select-----------------------//
  const [selectedOption, setSelectedOption] = useState(null);
  const [proposalDescription, setProposalDescription] = useState("");

  const handleSelect = (option) => {
    setSelectedOption(option);
  };

  //----------------------projects select--------------------//
  const [selectedProjects, setSelectedProjects] = useState([]);

  const handleProjectSelect = (projectName) => {
    // Toggle selection of projects
    setSelectedProjects((prevSelected) =>
      prevSelected.includes(projectName)
        ? prevSelected.filter((name) => name !== projectName)
        : [...prevSelected, projectName]
    );
  };

  const isSelected = (projectName) => selectedProjects.includes(projectName);

  //-----------------------dropzone-------------------------//
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const onDrop = useCallback((acceptedFiles) => {
    setUploadedFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
  }, []);

  const {
    getRootProps: getRootPropsGeneral,
    getInputProps: getInputPropsGeneral,
    isDragActive: isDragActiveGeneral,
  } = useDropzone({
    onDrop,
    accept: "application/pdf, image/*",
  });

  //-----------------------handle form submission-------------------------//
  const handleSubmit = () => {
    const formData = {
      // selectedOption,
      // selectedProjects,
      // uploadedFiles: uploadedFiles.map((file) => ({
      //   name: file.name,
      //   type: file.type,
      // })),
      proposalDescription,
    };
    // Send the JSON data to the n8n webhook hosted on your server
    setLoading(true); // Start loading when the request begins

    fetch(
      "https://flowbuilder.axillio.io/webhook/6203d5b3-d1a3-4274-b288-ddbe386f0b5e",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setLoading(false); // Stop loading
        const proposalUrl = data.proposalUrl; // Access proposalUrl directly
        console.log("Proposal URL from API:", proposalUrl); // Debug: Check the URL returned
        if (proposalUrl) {
          setProposalUrl(proposalUrl); // Update the state with the proposal URL
          console.log("State updated with URL:", proposalUrl); // Debug: Ensure state is being updated
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false); // Stop loading on error
      });
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-[#e6e2dd]">
        <ClipLoader size={50} color={"#8b8986"} loading={loading} />
      </div>
    );
  }

  // Show error message if proposalUrl is specifically "0"
  if (proposalUrl === "0") {
    return (
      <div className="flex items-center justify-center min-h-screen bg-[#e6e2dd] font-gilroyBold text-3xl underline">
        Technical difficulty encountered. Please try again.
      </div>
    );
  }

  // Show the proposal link if proposalUrl is a valid, non-"0" URL
  if (proposalUrl && proposalUrl !== "0") {
    return (
      <div className="flex items-center justify-center min-h-screen bg-[#e6e2dd] font-gilroyBold text-3xl underline">
        <Link to={proposalUrl}>Click here to view your proposal</Link>
      </div>
    );
  }

  //-------------------------------------------------------//
  return (
    <>
      <ScrollToTop />
      <div className="bg-[#e6e2dd] pt-12 min-h-screen font-gilroyRegular">
        {/* navbar */}
        <div className="flex flex-row items-center justify-between px-4 md:px-16 lg:px-32 xl:px-48">
          <Link to={"/"}>
            <img
              alt="axillio-logo"
              src="./images/axillio-logo.png"
              className="w-full h-auto"
            />
          </Link>
        </div>
        {/* section one */}
        <div className="flex flex-col items-center justify-center px-8 pt-20 space-y-0 md:space-y-4 md:px-16 lg:px-24 xl:px-48 md:pt-28 ">
          <div className="flex flex-col items-center justify-center space-y-4 md:space-y-8 border-b border-[#8b8986] pb-8 w-full">
            <h1 className="text-[#303030] text-4xl md:text-5xl text-center font-gilroyBold">
              Proposal
            </h1>
          </div>
          <div className="flex flex-col items-center justify-center w-full px-2 space-y-8 md:px-20">
            {/* Proposal Description */}
            <div className="flex flex-col w-full pt-24 space-y-3 md:space-y-6 ">
              <h1 className="text-[#676665] text-lg sm:text-xl md:text-2xl text-left font-semibold">
                Proposal Description
              </h1>

              <textarea
                placeholder="Enter here ..."
                value={proposalDescription}
                onChange={(e) => setProposalDescription(e.target.value)}
                className="border-2 border-[#c2bfbb] text-lg sm:text-xl md:text-2xl bg-[#e6e2dd] text-[#8b8986] p-2 w-full  resize-none min-h-[350px] max-h-[400px]"
                rows="8"
              />
            </div>

            {/* Upload Document */}
            {/* <div className="flex flex-col w-full pt-24 space-y-3 md:space-y-6 ">
              <h1 className="text-[#676665] text-lg sm:text-xl md:text-2xl text-left font-semibold">
                Upload any Relevant Document{" "}
              </h1>
              <div
                {...getRootPropsGeneral()}
                className="flex items-center justify-center w-full border-2 border-[#c2bfbb] p-4 min-h-[250px] space-y-4 flex-col hover:cursor-pointer"
              >
                <input {...getInputPropsGeneral()} />
                <img alt="upload" src="/images/upload.png" />
                <h1 className="text-[#303030] text-xl md:text-2xl font-semibold">
                  {isDragActiveGeneral
                    ? "Drop your document here..."
                    : "Upload Document"}
                </h1>
                <h1 className="text-[#94928f] text-md md:text-lg font-semibold">
                  (Drag & Drop or Upload from device)
                </h1>

                <div className="w-full mt-4">
                  {uploadedFiles.length > 0 && (
                    <div className="flex flex-col w-full space-y-2">
                      {uploadedFiles.map((file, index) => (
                        <div
                          key={index}
                          className="flex items-center justify-between w-full p-2 border border-[#c2bfbb] rounded-lg"
                        >
                          {file.type.startsWith("image/") && (
                            <img
                              src={URL.createObjectURL(file)}
                              alt="Preview"
                              className="object-cover w-16 h-16 mr-4 rounded-md"
                            />
                          )}
                          <span className="text-gray-700 break-words text-md">
                            {file.name}
                          </span>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div> */}

            {/* Options Select */}
            {/* <div className="flex flex-col w-full pt-24 space-y-3 md:space-y-6">
              <h1 className="text-[#676665] text-lg sm:text-xl md:text-2xl text-left font-semibold">
                Select one of the following:
              </h1>
              <div className="flex flex-row space-x-4 md:space-x-12">
                <motion.div
                  whileHover={{ scale: 1.05 }}
                  onClick={() => handleSelect("Design Only")}
                  className={`cursor-pointer flex items-center justify-center w-full border-2 p-4 min-h-[250px] space-y-4 flex-col transition-all duration-300 ease-in-out ${
                    selectedOption === "Design Only"
                      ? "border-[#8b8986] scale-105"
                      : "border-[#c2bfbb]"
                  }`}
                >
                  <img alt="curve" src="/images/curve.png" />
                  <h1 className="text-[#303030] text-xl md:text-2xl font-semibold">
                    Design Only
                  </h1>
                </motion.div>

                <motion.div
                  whileHover={{ scale: 1.05 }}
                  onClick={() => handleSelect("Design And Development")}
                  className={`cursor-pointer flex items-center justify-center w-full border-2 p-4 min-h-[250px] space-y-4 flex-col transition-all duration-300 ease-in-out ${
                    selectedOption === "Design And Development"
                      ? "border-[#8b8986] scale-105"
                      : "border-[#c2bfbb]"
                  }`}
                >
                  <img
                    alt="app-development"
                    src="/images/app-development.png"
                  />
                  <h1 className="text-[#303030] text-xl md:text-2xl font-semibold">
                    Design and Development
                  </h1>
                </motion.div>
              </div>
            </div> */}

            {/* <div className="flex flex-col w-full pt-24 space-y-3 md:space-y-6">
              <h1 className="text-[#676665] text-lg sm:text-xl md:text-2xl text-left font-semibold">
                Select Relevant Projects:
              </h1>
              <div className="flex flex-col items-center justify-center w-full space-x-0 space-y-4 md:flex-row md:space-x-8 md:space-y-0">
                <motion.div
                  whileHover={{ scale: 1.05 }}
                  onClick={() => handleProjectSelect("Athlantix")}
                  className={`border-2 p-6 h-auto w-full flex flex-col space-y-4 overflow-wrap break-words cursor-pointer transition-all duration-300 ease-in-out ${
                    isSelected("Athlantix")
                      ? "border-[#8b8986] scale-105"
                      : "border-[#c2bfbb]"
                  }`}
                >
                  <img alt="blank" src="/images/blank.png" />
                  <div className="flex flex-col pb-2 space-y-2">
                    <div className="flex flex-row items-center space-x-2">
                      <img alt="athlantix" src="/images/athlantix-small.png" />
                      <h1 className="text-[#303030] text-md md:text-lg text-left font-semibold">
                        Athlantix
                      </h1>
                    </div>
                    <h1 className="text-[#676665] text-lg md:text-xl text-left">
                      We improved customer engagement for Athlantix by
                      implementing a multi-channel strategy and building a
                      personalized recommendation engine.
                    </h1>
                  </div>
                  <div className="flex flex-row items-center space-x-2">
                    <h1 className="text-[#676665] text-sm md:text-md p-1 border border-[#c2bfbb]">
                      Web Design
                    </h1>
                    <h1 className="text-[#676665] text-sm md:text-md p-1 border border-[#c2bfbb]">
                      Landing page design
                    </h1>
                    <h1 className="text-[#676665] text-sm md:text-md p-1 border border-[#c2bfbb]">
                      Branding
                    </h1>
                  </div>
                </motion.div>

                <motion.div
                  whileHover={{ scale: 1.05 }}
                  onClick={() => handleProjectSelect("Athlantix2")}
                  className={`border-2 p-6 h-auto w-full flex flex-col space-y-4 overflow-wrap break-words cursor-pointer transition-all duration-300 ease-in-out ${
                    isSelected("Athlantix2")
                      ? "border-[#8b8986] scale-105"
                      : "border-[#c2bfbb]"
                  }`}
                >
                  <img alt="blank" src="/images/blank.png" />
                  <div className="flex flex-col pb-2 space-y-2">
                    <div className="flex flex-row items-center space-x-2">
                      <img alt="athlantix" src="/images/athlantix-small.png" />
                      <h1 className="text-[#303030] text-md md:text-lg text-left font-semibold">
                        Athlantix 2
                      </h1>
                    </div>
                    <h1 className="text-[#676665] text-lg md:text-xl text-left">
                      We improved customer engagement for Athlantix by
                      implementing a multi-channel strategy and building a
                      personalized recommendation engine.
                    </h1>
                  </div>
                  <div className="flex flex-row items-center space-x-2">
                    <h1 className="text-[#676665] text-sm md:text-md p-1 border border-[#c2bfbb]">
                      Web Design
                    </h1>
                    <h1 className="text-[#676665] text-sm md:text-md p-1 border border-[#c2bfbb]">
                      Landing page design
                    </h1>
                    <h1 className="text-[#676665] text-sm md:text-md p-1 border border-[#c2bfbb]">
                      Branding
                    </h1>
                  </div>
                </motion.div>

                <motion.div
                  whileHover={{ scale: 1.05 }}
                  onClick={() => handleProjectSelect("Athlantix3")}
                  className={`border-2 p-6 h-auto w-full flex flex-col space-y-4 overflow-wrap break-words cursor-pointer transition-all duration-300 ease-in-out ${
                    isSelected("Athlantix3")
                      ? "border-[#8b8986] scale-105"
                      : "border-[#c2bfbb]"
                  }`}
                >
                  <img alt="blank" src="/images/blank.png" />
                  <div className="flex flex-col pb-2 space-y-2">
                    <div className="flex flex-row items-center space-x-2">
                      <img alt="athlantix" src="/images/athlantix-small.png" />
                      <h1 className="text-[#303030] text-md md:text-lg text-left font-semibold">
                        Athlantix 3
                      </h1>
                    </div>
                    <h1 className="text-[#676665] text-lg md:text-xl text-left">
                      We improved customer engagement for Athlantix by
                      implementing a multi-channel strategy and building a
                      personalized recommendation engine.
                    </h1>
                  </div>
                  <div className="flex flex-row items-center space-x-2">
                    <h1 className="text-[#676665] text-sm md:text-md p-1 border border-[#c2bfbb]">
                      Web Design
                    </h1>
                    <h1 className="text-[#676665] text-sm md:text-md p-1 border border-[#c2bfbb]">
                      Landing page design
                    </h1>
                    <h1 className="text-[#676665] text-sm md:text-md p-1 border border-[#c2bfbb]">
                      Branding
                    </h1>
                  </div>
                </motion.div>
              </div>
            </div> */}

            {/* Save Button */}
            <div className="flex items-center justify-center w-full py-16 md:py-28">
              <Button
                onClick={handleSubmit}
                radius="sm"
                variant="filled"
                color="#303030"
                size="xl"
              >
                Save & Upload Proposal
              </Button>
            </div>
          </div>
        </div>
        {/* footer */}
        <div className="bg-[#1a1a1a] flex flex-col space-y-16 md:space-y-28 pb-12 pt-12 md:pt-24 mt-32 md:mt-64 px-8 md:px-16 lg:px-32 xl:px-48">
          <div className="flex flex-col items-center justify-between space-y-8 md:space-y-12">
            <h1 className="text-sm md:text-md text-[#d6d2cd]">Follow us on:</h1>
            <div className="flex flex-row items-center justify-center space-x-4 md:space-x-8">
              <a
                href="https://www.behance.net/axillio"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt="behance"
                  src="./images/behance_logo.png"
                  className="w-8 h-auto md:w-12 lg:w-full"
                />
              </a>
              <a
                href="https://x.com/AliMukhtar081"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt="x"
                  src="./images/x_logo.png"
                  className="w-8 h-auto md:w-12 lg:w-full"
                />
              </a>
              <a
                href="https://dribbble.com/axillio"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt="football"
                  src="./images/footbal-logo.png"
                  className="w-8 h-auto md:w-12 lg:w-full"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/axillio/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt="linkedin"
                  src="./images/linkedin_symbol.png"
                  className="w-8 h-auto md:w-12 lg:w-full"
                />
              </a>
              <a
                href="https://www.instagram.com/axillioofficial/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt="insta"
                  src="./images/insta-logo.png"
                  className="w-8 h-auto md:w-12 lg:w-full"
                />
              </a>
              <a
                href="https://www.youtube.com/@Axillio"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt="youtube"
                  src="./images/youtube-logo.png"
                  className="w-8 h-auto md:w-12 lg:w-full"
                />
              </a>
            </div>

            <h1 className="text-sm md:text-md text-[#d6d2cd]">
              © 2024 Axillio. All Rights Reserved
            </h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProposalForm;
