// import { useState } from "react";
// import VideoLinkModal from "./VideoLinkModal";

// const VideoSection = ({ proposalData, id }) => {
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [videoLink, setVideoLink] = useState(proposalData.loomVideoLink || "");
//   const [isLoading, setIsLoading] = useState(false);
//   const [error, setError] = useState("");

//   const handleVideoSubmit = async (newVideoLink) => {
//     setIsLoading(true);
//     setError("");

//     try {
//       const response = await fetch(
//         "https://flowbuilder.axillio.io/webhook/e14333b2-1f87-469a-a46e-9d22cb1d00df",
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify({
//             id: id,
//             updateType: "videoLink",
//             videoLink: newVideoLink,
//           }),
//         }
//       );

//       if (!response.ok) {
//         throw new Error("Failed to update video link");
//       }

//       const updatedData = await response.json();
//       setVideoLink(newVideoLink);
//     } catch (err) {
//       setError("Failed to save video link. Please try again.");
//       console.error("Error updating video link:", err);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const renderVideo = () => {
//     if (!videoLink) return null;

//     // Extract video ID from Loom link
//     const videoId = videoLink.split("/").pop();

//     return (
//       <div style={{ position: "relative", paddingBottom: "56.25%", height: 0 }}>
//         <iframe
//           src={`https://www.loom.com/embed/${videoId}`}
//           frameBorder="0"
//           allowFullScreen
//           style={{
//             position: "absolute",
//             top: 0,
//             left: 0,
//             width: "100%",
//             height: "100%",
//           }}
//         />
//       </div>
//     );
//   };

//   return (
//     <div className="flex flex-col items-center justify-center w-full px-4 pt-24 space-y-8 md:space-y-16 md:px-0">
//       <div className="flex flex-row justify-between w-full">
//         <h1 className="text-[#303030] text-2xl sm:text-3xl md:text-4xl text-center font-gilroyBold">
//           Loom Video
//         </h1>
//         {!videoLink ? (
//           <button
//             onClick={() => setIsModalOpen(true)}
//             className="px-6 py-2 bg-[#303030] text-white hover:bg-[#404040]"
//             disabled={isLoading}
//           >
//             {isLoading ? "Adding Video..." : "Add Video Link"}
//           </button>
//         ) : (
//           <button
//             onClick={() => setIsModalOpen(true)}
//             className=" mt-4 px-6 py-2 border border-[#8b8986] text-[#8b8986] hover:text-[#303030] hover:border-[#303030]"
//             disabled={isLoading}
//           >
//             Change Video Link
//           </button>
//         )}
//       </div>

//       <div className="w-full border border-[#8b8986] p-4 min-h-[50vh]">
//         {error && <div className="mb-4 text-center text-red-500">{error}</div>}

//         {videoLink ? (
//           <div className="w-full h-full">{renderVideo()}</div>
//         ) : (
//           <div className="flex items-center justify-center w-full h-[80vh] ">
//             <h1 className="text-[#676665] text-lg md:text-xl font-semibold">
//               Ali is currently Recording the Video. Please wait!{" "}
//             </h1>
//           </div>
//         )}
//       </div>

//       <VideoLinkModal
//         isOpen={isModalOpen}
//         onClose={() => setIsModalOpen(false)}
//         onSubmit={handleVideoSubmit}
//       />
//     </div>
//   );
// };

// export default VideoSection;
import { useState } from "react";
import VideoLinkModal from "./VideoLinkModal";

const VideoSection = ({ proposalData, id, isEditMode }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [videoLink, setVideoLink] = useState(proposalData.loomVideoLink || "");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const handleVideoSubmit = async (newVideoLink) => {
    setIsLoading(true);
    setError("");

    try {
      const response = await fetch(
        "https://flowbuilder.axillio.io/webhook/e14333b2-1f87-469a-a46e-9d22cb1d00df",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: id,
            updateType: "videoLink",
            videoLink: newVideoLink,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update video link");
      }

      const updatedData = await response.json();
      setVideoLink(newVideoLink);
    } catch (err) {
      setError("Failed to save video link. Please try again.");
      console.error("Error updating video link:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const renderVideo = () => {
    if (!videoLink) return null;

    // Extract video ID from Loom link
    const videoId = videoLink.split("/").pop();

    return (
      <div style={{ position: "relative", paddingBottom: "56.25%", height: 0 }}>
        <iframe
          src={`https://www.loom.com/embed/${videoId}`}
          frameBorder="0"
          allowFullScreen
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
        />
      </div>
    );
  };

  return (
    <div className="flex flex-col items-center justify-center w-full px-4 pt-24 space-y-8 md:space-y-16 md:px-0">
      <div className="flex flex-row justify-between w-full">
        <h1 className="text-[#303030] text-2xl sm:text-3xl md:text-4xl text-center font-gilroyBold">
          Loom Video
        </h1>
        <div className="flex flex-row items-center space-x-2">
          <div className="flex flex-row justify-between w-full">
            {isEditMode &&
              (!videoLink ? (
                <button
                  onClick={() => setIsModalOpen(true)}
                  className="px-6 py-2 bg-[#303030] text-white hover:bg-[#404040]"
                  disabled={isLoading}
                >
                  {isLoading ? "Adding Video..." : "Add Video Link"}
                </button>
              ) : (
                <button
                  onClick={() => setIsModalOpen(true)}
                  className="px-6 py-2 border border-[#8b8986] text-[#8b8986] hover:text-[#303030] hover:border-[#303030]"
                  disabled={isLoading}
                >
                  Change Video Link
                </button>
              ))}
          </div>
        </div>
      </div>

      <div className="w-full border border-[#8b8986] p-4 min-h-[50vh]">
        {error && <div className="mb-4 text-center text-red-500">{error}</div>}

        {videoLink ? (
          <div className="w-full h-full">{renderVideo()}</div>
        ) : (
          <div className="flex items-center justify-center w-full h-[80vh] ">
            <h1 className="text-[#676665] text-lg md:text-xl font-semibold">
              Ali is currently Recording the Video. Please wait!{" "}
            </h1>
          </div>
        )}
      </div>

      <VideoLinkModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={handleVideoSubmit}
      />
    </div>
  );
};

export default VideoSection;
