// import { Link } from "react-router-dom";
// import React, { useEffect, useState } from "react";
// import ScrollToTop from "../components/ScrollToTop";

// const Proposal = () => {
//   return (
//     <>
//       <ScrollToTop />
//       <div className="bg-[#e6e2dd] pt-12 min-h-screen font-gilroyRegular">
//         {/* navbar */}
//         <div className="flex flex-row items-center justify-between px-4 md:px-16 lg:px-32 xl:px-48">
//           <Link to={"/"}>
//             <img
//               alt="axillio-logo"
//               src="./images/axillio-logo.png"
//               className="w-full h-auto"
//             />
//           </Link>
//         </div>
//         {/* section one */}
//         <div className="flex flex-col items-center justify-center px-8 pt-20 space-y-12 md:px-16 lg:px-24 xl:px-48 md:pt-28 ">
//           <div className="flex flex-col items-center justify-center space-y-4 md:space-y-8">
//             <h1 className="text-[#303030] text-4xl md:text-5xl text-center font-gilroyBold">
//               Proposal
//             </h1>
//             <h1 className="text-[#303030] text-lg md:text-xl font-semibold">
//               for [Client/Project Name]{" "}
//             </h1>
//           </div>

//           <div className="flex flex-row justify-between items-center border-b border-[#8b8986] w-full">
//             <h1 className="text-[#303030] text-lg md:text-xl font-semibold">
//               Prepared by Axillio{" "}
//             </h1>
//             <h1 className="text-[#303030] text-lg md:text-xl font-semibold">
//               [Date]
//             </h1>
//           </div>

//           <div className="flex flex-col items-center justify-center px-4 pt-12 space-y-8 md:space-y-16 md:px-0">
//             <h1 className="text-[#303030] text-2xl sm:text-3xl md:text-4xl text-center font-gilroyBold">
//               Area for Consideration{" "}
//             </h1>
//             <div className="flex flex-col space-y-4">
//               <div className="flex flex-col items-center justify-center space-x-0 space-y-4 md:flex-row md:space-x-4 md:space-y-0">
//                 <div className="border border-[#8b8986] w-full md:w-[40vh] py-8 px-5 space-x-8 flex flex-row items-center">
//                   <h1 className="text-[#e6e2dd] text-lg md:text-xl text-left font-semibold  bg-[#303030] px-4 py-2">
//                     1.
//                   </h1>
//                   <h1 className="text-[#303030] text-lg md:text-xl text-left font-semibold">
//                     Performance Optimization{" "}
//                   </h1>
//                 </div>
//                 <div className="border border-[#8b8986] w-full md:w-[40vh] py-8 px-5 space-x-8 flex flex-row items-center">
//                   <h1 className="text-[#e6e2dd] text-lg md:text-xl text-left font-semibold  bg-[#303030] px-4 py-2">
//                     2.
//                   </h1>
//                   <h1 className="text-[#303030] text-lg md:text-xl text-left font-semibold">
//                     Scalability and Growth{" "}
//                   </h1>
//                 </div>
//               </div>
//               <div className="flex flex-col items-center justify-center space-x-0 space-y-4 md:flex-row md:space-x-4 md:space-y-0">
//                 <div className="border border-[#8b8986] w-full md:w-[40vh] py-8 px-5 space-x-8 flex flex-row items-center">
//                   <h1 className="text-[#e6e2dd] text-lg md:text-xl text-left font-semibold  bg-[#303030] px-4 py-2">
//                     3.
//                   </h1>
//                   <h1 className="text-[#303030] text-lg md:text-xl text-left font-semibold">
//                     Security and Compliance{" "}
//                   </h1>
//                 </div>
//                 <div className="border border-[#8b8986] w-full md:w-[40vh] py-8 px-5 space-x-8 flex flex-row items-center">
//                   <h1 className="text-[#e6e2dd] text-lg md:text-xl text-left font-semibold  bg-[#303030] px-4 py-2">
//                     4.
//                   </h1>
//                   <h1 className="text-[#303030] text-lg md:text-xl text-left font-semibold">
//                     User Experience Design{" "}
//                   </h1>
//                 </div>
//               </div>{" "}
//               <div className="flex flex-col items-center justify-center space-x-0 space-y-4 md:flex-row md:space-x-4 md:space-y-0">
//                 <div className="border border-[#8b8986] w-full md:w-[40vh] py-8 px-5 space-x-8 flex flex-row items-center">
//                   <h1 className="text-[#e6e2dd] text-lg md:text-xl text-left font-semibold  bg-[#303030] px-4 py-2">
//                     5.
//                   </h1>
//                   <h1 className="text-[#303030] text-lg md:text-xl text-left font-semibold">
//                     Cost Efficiency{" "}
//                   </h1>
//                 </div>
//                 <div className="border border-[#8b8986] w-full md:w-[40vh] py-8 px-5 space-x-8 flex flex-row items-center">
//                   <h1 className="text-[#e6e2dd] text-lg md:text-xl text-left font-semibold  bg-[#303030] px-4 py-2">
//                     6.
//                   </h1>
//                   <h1 className="text-[#303030] text-lg md:text-xl text-left font-semibold">
//                     Technology Integration{" "}
//                   </h1>
//                 </div>
//               </div>
//             </div>
//           </div>

// <div className="flex flex-col items-center justify-center w-full px-4 pt-24 space-y-8 md:space-y-16 md:px-0">
//   <h1 className="text-[#303030] text-2xl sm:text-3xl md:text-4xl text-center font-gilroyBold">
//     Loom Video
//   </h1>
//   <div className="flex items-center justify-center w-full h-[80vh] border border-[#8b8986] p-4">
//     <h1 className="text-[#676665] text-lg md:text-xl font-semibold">
//       Ali is currently Recording the Video. Please wait!{" "}
//     </h1>
//   </div>
// </div>

//           <div className="flex flex-col items-center justify-center w-full px-4 pt-24 space-y-8 md:space-y-16 md:px-0">
//             <h1 className="text-[#303030] text-2xl sm:text-3xl md:text-4xl text-center font-gilroyBold">
//               Related Experience
//             </h1>
//             <div className="flex flex-col w-full space-y-4">
//               <div className="flex flex-col items-center justify-center w-full space-x-0 space-y-4 md:flex-row md:space-x-4 md:space-y-0">
//                 <div className="border border-[#8b8986] flex flex-col space-y-4 p-6 w-full h-auto   overflow-wrap break-words max-w-full">
//                   <div className="flex flex-col space-y-2 pb-2 border-b border-[#8b8986]">
//                     <h1 className="text-[#8b8986] text-md md:text-lg text-left  ">
//                       Problem
//                     </h1>
//                     <h1 className="text-[#303030] text-2xl md:text-3xl text-left font-gilroyBold px-3">
//                       1. Customer Engagement and Retention{" "}
//                     </h1>
//                   </div>

//                   <div className="flex flex-col pb-2 space-y-2 ">
//                     <h1 className="text-[#8b8986] text-md md:text-lg text-left  ">
//                       Solution
//                     </h1>
//                     <div className="flex flex-row items-center space-x-2">
//                       <img alt="athlantix" src="/images/athlantix-small.png" />
//                       <h1 className="text-[#303030] text-md md:text-lg text-left font-semibold ">
//                         Athlantix
//                       </h1>
//                     </div>
//                     <h1 className="text-[#676665] text-lg md:text-xl text-left  ">
//                       We improved customer engagement for Athlantix by
//                       implementing a multi-channel strategy and building a
//                       personalized recommendation engine.{" "}
//                     </h1>
//                   </div>

//                   <div className="flex flex-col pb-2 space-y-2 ">
//                     <h1 className="text-[#303030] text-md md:text-lg text-left font-semibold ">
//                       Technologies/ Tools:
//                     </h1>
//                     <div className="flex flex-row items-center space-x-6">
//                       <img alt="react" src="/images/react-small.png" />
//                       <img alt="aws" src="/images/aws-small.png" />
//                       <img alt="glass" src="/images/glass-small.png" />
//                     </div>
//                   </div>
//                   <div className="flex flex-col pb-2 space-y-2 ">
//                     <h1 className="text-[#303030] text-md md:text-lg text-left font-semibold ">
//                       Problem solved:{" "}
//                     </h1>
//                     <h1 className="text-[#676665] text-lg md:text-xl text-left  ">
//                       “Customer Retention Optimization for Athlantix.”
//                     </h1>
//                   </div>
//                   <div className="flex flex-col pb-2 space-y-4 ">
//                     <h1 className="text-[#303030] text-md md:text-lg text-left font-semibold ">
//                       Results:{" "}
//                     </h1>
//                     <h1 className="text-[#676665] text-lg md:text-xl text-left  ">
//                       Increased customer engagement by 35% in 3 months.
//                     </h1>
//                   </div>
//                 </div>
//                 <div className="border border-[#8b8986] flex  flex-col space-y-4 p-6 w-full h-auto pb-16  overflow-wrap break-words max-w-full">
//                   <div className="flex flex-col space-y-2 pb-2 border-b border-[#8b8986]">
//                     <h1 className="text-[#8b8986] text-md md:text-lg text-left  ">
//                       Problem
//                     </h1>
//                     <h1 className="text-[#303030] text-2xl md:text-3xl text-left font-gilroyBold px-3">
//                       2. Marketing Optimization
//                     </h1>
//                   </div>

//                   <div className="flex flex-col pb-2 space-y-2 ">
//                     <h1 className="text-[#8b8986] text-md md:text-lg text-left  ">
//                       Solution
//                     </h1>
//                     <div className="flex flex-row items-center space-x-2">
//                       <img alt="athlantix" src="/images/athlantix-small.png" />
//                       <h1 className="text-[#303030] text-md md:text-lg text-left font-semibold ">
//                         Athlantix
//                       </h1>
//                     </div>
//                     <h1 className="text-[#676665] text-lg md:text-xl text-left  ">
//                       We improved customer engagement for Athlantix by
//                       implementing a multi-channel strategy and building a
//                       personalized recommendation engine.{" "}
//                     </h1>
//                   </div>

//                   <div className="flex flex-col pb-2 space-y-2 ">
//                     <h1 className="text-[#303030] text-md md:text-lg text-left font-semibold ">
//                       Technologies/ Tools:
//                     </h1>
//                     <div className="flex flex-row items-center space-x-6">
//                       <img alt="react" src="/images/react-small.png" />
//                       <img alt="aws" src="/images/aws-small.png" />
//                       <img alt="glass" src="/images/glass-small.png" />
//                     </div>
//                   </div>
//                   <div className="flex flex-col pb-2 space-y-2 ">
//                     <h1 className="text-[#303030] text-md md:text-lg text-left font-semibold ">
//                       Problem solved:{" "}
//                     </h1>
//                     <h1 className="text-[#676665] text-lg md:text-xl text-left  ">
//                       “Customer Retention Optimization for Athlantix.”
//                     </h1>
//                   </div>
//                   <div className="flex flex-col pb-2 space-y-4 ">
//                     <h1 className="text-[#303030] text-md md:text-lg text-left font-semibold ">
//                       Results:{" "}
//                     </h1>
//                     <h1 className="text-[#676665] text-lg md:text-xl text-left  ">
//                       Increased customer engagement by 35% in 3 months.
//                     </h1>
//                   </div>
//                 </div>{" "}
//                 <div className="border border-[#8b8986] flex  flex-col space-y-4 p-6 w-full h-auto pb-16 overflow-wrap break-words max-w-full">
//                   <div className="flex flex-col space-y-2 pb-2 border-b border-[#8b8986]">
//                     <h1 className="text-[#8b8986] text-md md:text-lg text-left  ">
//                       Problem
//                     </h1>
//                     <h1 className="text-[#303030] text-2xl md:text-3xl text-left font-gilroyBold px-3">
//                       3. UX Improvement
//                     </h1>
//                   </div>

//                   <div className="flex flex-col pb-2 space-y-2 ">
//                     <h1 className="text-[#8b8986] text-md md:text-lg text-left  ">
//                       Solution
//                     </h1>
//                     <div className="flex flex-row items-center space-x-2">
//                       <img alt="athlantix" src="/images/athlantix-small.png" />
//                       <h1 className="text-[#303030] text-md md:text-lg text-left font-semibold ">
//                         Athlantix
//                       </h1>
//                     </div>
//                     <h1 className="text-[#676665] text-lg md:text-xl text-left  ">
//                       We improved customer engagement for Athlantix by
//                       implementing a multi-channel strategy and building a
//                       personalized recommendation engine.{" "}
//                     </h1>
//                   </div>

//                   <div className="flex flex-col pb-2 space-y-2 ">
//                     <h1 className="text-[#303030] text-md md:text-lg text-left font-semibold ">
//                       Technologies/ Tools:
//                     </h1>
//                     <div className="flex flex-row items-center space-x-6">
//                       <img alt="react" src="/images/react-small.png" />
//                       <img alt="aws" src="/images/aws-small.png" />
//                       <img alt="glass" src="/images/glass-small.png" />
//                     </div>
//                   </div>
//                   <div className="flex flex-col pb-2 space-y-2 ">
//                     <h1 className="text-[#303030] text-md md:text-lg text-left font-semibold ">
//                       Problem solved:{" "}
//                     </h1>
//                     <h1 className="text-[#676665] text-lg md:text-xl text-left  ">
//                       “Customer Retention Optimization for Athlantix.”
//                     </h1>
//                   </div>
//                   <div className="flex flex-col pb-2 space-y-4 ">
//                     <h1 className="text-[#303030] text-md md:text-lg text-left font-semibold ">
//                       Results:{" "}
//                     </h1>
//                     <h1 className="text-[#676665] text-lg md:text-xl text-left  ">
//                       Increased customer engagement by 35% in 3 months.
//                     </h1>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="flex flex-col w-full px-4 pt-24 space-y-8 md:space-y-16 md:px-0">
//             <h1 className="text-[#303030] text-2xl sm:text-3xl md:text-4xl text-center font-gilroyBold">
//               Timeline for Design & Development{" "}
//             </h1>
//             <div className="relative flex flex-col ">
//               {/* First Row */}
//               <div className="flex flex-row items-center justify-start ">
//                 <h1 className="text-[#303030] text-xl md:text-2xl border-2 border-[#c2bfbb] py-2 px-4 font-semibold relative">
//                   01
//                   {/* Vertical line from 01 to 02 */}
//                   <span className="absolute bottom-[-63px] sm:bottom-[-50px] left-1/2 transform -translate-x-1/2 w-[4px] h-[63px] sm:h-[50px] bg-[#303030]"></span>
//                 </h1>

//                 {/* Horizontal Line between 01 and the section */}
//                 <span className="w-[30px] h-[2px] bg-[#303030]"></span>

//                 <div className="flex flex-row items-center justify-between border-2 border-[#c2bfbb] w-[70vh] p-4">
//                   <h1 className="text-[#303030] text-md sm:text-2xl  py-2 px-4 font-semibold">
//                     Initial Design Research{" "}
//                   </h1>
//                   <h1 className="text-[#e6e2dd] text-md sm:text-xl bg-[#545352] py-2 px-4 font-semibold">
//                     2 Weeks
//                   </h1>
//                 </div>
//               </div>
//               {/* second Row */}
//               <div className="flex flex-row items-center justify-start mt-4 ">
//                 <h1 className="text-[#303030] text-xl md:text-2xl border-2 border-[#c2bfbb] py-2 px-4 font-semibold relative">
//                   02
//                   {/* Vertical line from 01 to 02 */}
//                   <span className="absolute bottom-[-63px] sm:bottom-[-50px] left-1/2 transform -translate-x-1/2 w-[4px] h-[63px] sm:h-[50px] bg-[#303030]"></span>
//                 </h1>

//                 {/* Horizontal Line between 01 and the section */}
//                 <span className="w-[30px] h-[2px] bg-[#303030]"></span>

//                 <div className="flex flex-row items-center justify-between border-2 border-[#c2bfbb] w-[80vh] p-4">
//                   <h1 className="text-[#303030] text-md sm:text-2xl  py-2 px-4 font-semibold">
//                     Wireframes & Prototyping{" "}
//                   </h1>
//                   <h1 className="text-[#e6e2dd] text-md sm:text-xl bg-[#545352] py-2 px-4 font-semibold">
//                     3 Weeks
//                   </h1>
//                 </div>
//               </div>
//               {/* third Row */}
//               <div className="flex flex-row items-center justify-start mt-4">
//                 <h1 className="text-[#303030] text-xl md:text-2xl border-2 border-[#c2bfbb] py-2 px-4 font-semibold relative">
//                   03
//                   {/* Vertical line from 01 to 02 */}
//                   <span className="absolute bottom-[-63px] sm:bottom-[-50px] left-1/2 transform -translate-x-1/2 w-[4px] h-[63px] sm:h-[50px] bg-[#303030]"></span>
//                 </h1>

//                 {/* Horizontal Line between 01 and the section */}
//                 <span className="w-[30px] h-[2px] bg-[#303030]"></span>

//                 <div className="flex flex-row items-center justify-between border-2 border-[#c2bfbb] w-[90vh] p-4">
//                   <h1 className="text-[#303030] text-md sm:text-2xl  py-2 px-4 font-semibold">
//                     UI Design & Review{" "}
//                   </h1>
//                   <h1 className="text-[#e6e2dd] text-md sm:text-xl bg-[#545352] py-2 px-4 font-semibold">
//                     4 Weeks
//                   </h1>
//                 </div>
//               </div>
//               {/* forth Row */}
//               <div className="flex flex-row items-center justify-start mt-4">
//                 <h1 className="text-[#303030] text-xl md:text-2xl border-2 border-[#c2bfbb] py-2 px-4 font-semibold relative">
//                   04
//                   {/* Vertical line from 01 to 02 */}
//                   <span className="absolute bottom-[-63px] sm:bottom-[-50px] left-1/2 transform -translate-x-1/2 w-[4px] h-[63px] sm:h-[50px] bg-[#303030]"></span>
//                 </h1>

//                 {/* Horizontal Line between 01 and the section */}
//                 <span className="w-[30px] h-[2px] bg-[#303030]"></span>

//                 <div className="flex flex-row items-center justify-between border-2 border-[#c2bfbb] w-[60vh] p-4">
//                   <h1 className="text-[#303030] text-md sm:text-2xl  py-2 px-4 font-semibold">
//                     Final Design Delivery{" "}
//                   </h1>
//                   <h1 className="text-[#e6e2dd] text-md sm:text-xl bg-[#545352] py-2 px-4 font-semibold">
//                     1 Week
//                   </h1>
//                 </div>
//               </div>
//               {/* fifth Row */}
//               <div className="flex flex-row items-center justify-start mt-4">
//                 <h1 className="text-[#303030] text-xl md:text-2xl border-2 border-[#c2bfbb] py-2 px-4 font-semibold relative">
//                   05
//                   {/* Vertical line from 01 to 02 */}
//                   <span className="absolute bottom-[-63px] sm:bottom-[-50px] left-1/2 transform -translate-x-1/2 w-[4px] h-[63px] sm:h-[50px] bg-[#303030]"></span>
//                 </h1>

//                 {/* Horizontal Line between 01 and the section */}
//                 <span className="w-[30px] h-[2px] bg-[#303030]"></span>

//                 <div className="flex flex-row items-center justify-between border-2 border-[#c2bfbb] w-[90vh] p-4">
//                   <h1 className="text-[#303030] text-md sm:text-2xl  py-2 px-4 font-semibold">
//                     Frontend Development{" "}
//                   </h1>
//                   <h1 className="text-[#e6e2dd] text-md sm:text-xl bg-[#545352] py-2 px-4 font-semibold">
//                     4 Weeks
//                   </h1>
//                 </div>
//               </div>
//               {/* sixth Row */}
//               <div className="flex flex-row items-center justify-start mt-4">
//                 <h1 className="text-[#303030] text-xl md:text-2xl border-2 border-[#c2bfbb] py-2 px-4 font-semibold relative">
//                   06
//                   {/* Vertical line from 01 to 02 */}
//                   <span className="absolute bottom-[-63px] sm:bottom-[-50px] left-1/2 transform -translate-x-1/2 w-[4px] h-[63px] sm:h-[50px] bg-[#303030]"></span>
//                 </h1>

//                 {/* Horizontal Line between 01 and the section */}
//                 <span className="w-[30px] h-[2px] bg-[#303030]"></span>

//                 <div className="flex flex-row items-center justify-between border-2 border-[#c2bfbb] w-[100vh] p-4">
//                   <h1 className="text-[#303030] text-md sm:text-2xl  py-2 px-4 font-semibold">
//                     Backend Development{" "}
//                   </h1>
//                   <h1 className="text-[#e6e2dd] text-md sm:text-xl bg-[#545352] py-2 px-4 font-semibold">
//                     5 Weeks
//                   </h1>
//                 </div>
//               </div>

//               {/* seventh Row */}
//               <div className="flex flex-row items-center justify-start mt-4">
//                 <h1 className="text-[#303030] text-xl md:text-2xl border-2 border-[#c2bfbb] py-2 px-4 font-semibold relative">
//                   07
//                   {/* Vertical line from 01 to 02 */}
//                   <span className="absolute bottom-[-63px] sm:bottom-[-50px] left-1/2 transform -translate-x-1/2 w-[4px] h-[63px] sm:h-[50px] bg-[#303030]"></span>
//                 </h1>

//                 {/* Horizontal Line between 01 and the section */}
//                 <span className="w-[30px] h-[2px] bg-[#303030]"></span>

//                 <div className="flex flex-row items-center justify-between border-2 border-[#c2bfbb] w-[80vh] p-4">
//                   <h1 className="text-[#303030] text-md sm:text-2xl  py-2 px-4 font-semibold">
//                     Testing & QA{" "}
//                   </h1>
//                   <h1 className="text-[#e6e2dd] text-md sm:text-xl bg-[#545352] py-2 px-4 font-semibold">
//                     3 Weeks
//                   </h1>
//                 </div>
//               </div>
//               {/* eigth Row */}
//               <div className="flex flex-row items-center justify-start mt-4">
//                 <h1 className="text-[#303030] text-xl md:text-2xl border-2 border-[#c2bfbb] py-2 px-4 font-semibold relative">
//                   08
//                   {/* Vertical line from 01 to 02 */}
//                   <span className="absolute bottom-[-63px] sm:bottom-[-50px] left-1/2 transform -translate-x-1/2 w-[4px] h-[63px] sm:h-[50px] bg-[#303030]"></span>
//                 </h1>

//                 {/* Horizontal Line between 01 and the section */}
//                 <span className="w-[30px] h-[2px] bg-[#303030]"></span>

//                 <div className="flex flex-row items-center justify-between border-2 border-[#c2bfbb] w-[70vh] p-4">
//                   <h1 className="text-[#303030] text-md sm:text-2xl  py-2 px-4 font-semibold">
//                     Launch & Deployment{" "}
//                   </h1>
//                   <h1 className="text-[#e6e2dd] text-md sm:text-xl bg-[#545352] py-2 px-4 font-semibold">
//                     2 Weeks
//                   </h1>
//                 </div>
//               </div>
//               {/* ninth/last Row */}
//               <div className="flex flex-row items-center justify-start mt-4">
//                 <h1 className="text-[#303030] bg-[#303030] text-xl md:text-2xl border-2 border-[#303030] py-2 px-4 font-semibold relative">
//                   02
//                 </h1>

//                 <span className="w-[30px] h-[2px] bg-[#303030]"></span>

//                 <div className="flex flex-row items-center justify-between border-2 border-[#c2bfbb] w-[130vh] p-4">
//                   <h1 className="text-[#303030] text-md sm:text-2xl  py-2 px-4 font-semibold">
//                     Total{" "}
//                   </h1>
//                   <h1 className="text-[#e6e2dd] text-md sm:text-xl bg-[#545352] py-2 px-4 font-semibold">
//                     24 Weeks
//                   </h1>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="flex flex-col items-center justify-center w-full px-4 pt-24 space-y-8 md:space-y-16 md:px-0">
//             <h1 className="text-[#303030] text-2xl sm:text-3xl md:text-4xl text-center font-gilroyBold">
//               Proposed Architectural Solution{" "}
//             </h1>
//             <div className="flex items-center justify-center w-full h-[80vh]  p-4 bg-[#b1aca7]"></div>
//           </div>
//         </div>
//         {/* footer */}
//         <div className="bg-[#1a1a1a] flex flex-col space-y-16 md:space-y-28 pb-12 pt-12 md:pt-24 mt-32 md:mt-64 px-8 md:px-16 lg:px-32 xl:px-48">
//           <div className="flex flex-col items-center justify-between space-y-8 md:space-y-12">
//             <h1 className="text-sm md:text-md text-[#d6d2cd]">Follow us on:</h1>
//             <div className="flex flex-row items-center justify-center space-x-4 md:space-x-8">
//               <a
//                 href="https://www.behance.net"
//                 target="_blank"
//                 rel="noopener noreferrer"
//               >
//                 <img
//                   alt="behance"
//                   src="./images/behance_logo.png"
//                   className="w-8 h-auto md:w-12 lg:w-full"
//                 />
//               </a>
//               <a
//                 href="https://x.com/AliMukhtar081"
//                 target="_blank"
//                 rel="noopener noreferrer"
//               >
//                 <img
//                   alt="x"
//                   src="./images/x_logo.png"
//                   className="w-8 h-auto md:w-12 lg:w-full"
//                 />
//               </a>
//               <a
//                 href="https://dribbble.com/axillio"
//                 target="_blank"
//                 rel="noopener noreferrer"
//               >
//                 <img
//                   alt="football"
//                   src="./images/footbal-logo.png"
//                   className="w-8 h-auto md:w-12 lg:w-full"
//                 />
//               </a>
//               <a
//                 href="https://www.linkedin.com/company/axillio/"
//                 target="_blank"
//                 rel="noopener noreferrer"
//               >
//                 <img
//                   alt="linkedin"
//                   src="./images/linkedin_symbol.png"
//                   className="w-8 h-auto md:w-12 lg:w-full"
//                 />
//               </a>
//               <a
//                 href="https://www.instagram.com/axillioofficial/"
//                 target="_blank"
//                 rel="noopener noreferrer"
//               >
//                 <img
//                   alt="insta"
//                   src="./images/insta-logo.png"
//                   className="w-8 h-auto md:w-12 lg:w-full"
//                 />
//               </a>
//               <a
//                 href="https://www.youtube.com/@Axillio"
//                 target="_blank"
//                 rel="noopener noreferrer"
//               >
//                 <img
//                   alt="youtube"
//                   src="./images/youtube-logo.png"
//                   className="w-8 h-auto md:w-12 lg:w-full"
//                 />
//               </a>
//             </div>

//             <h1 className="text-sm md:text-md text-[#d6d2cd]">
//               © 2024 Axillio. All Rights Reserved
//             </h1>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// // export default Proposal;
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import ScrollToTop from "../components/ScrollToTop";
import { useParams } from "react-router-dom";
import VideoSection from "../components/VideoSection";
import { Tooltip } from "react-tooltip";
import { FiEdit3, FiCheck } from "react-icons/fi";

const Proposal = () => {
  const { title, id } = useParams(); // Extract title and id from URL
  const [proposalData, setProposalData] = useState(null);
  const [error, setError] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    // Fetch proposal data using POST and send title and id in the request body
    fetch(
      "https://flowbuilder.axillio.io/webhook/e14333b2-1f87-469a-a46e-9d22cb1d00df",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ title, id }), // Send title and id in the body
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch proposal data");
        }
        return response.json();
      })
      .then((data) => {
        // Handle the returned proposal data
        setProposalData(data);
        console.log("Proposal data:", data);
      })
      .catch((error) => {
        setError(error.message);
        console.error("Error fetching proposal:", error);
      });
  }, [title, id]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!proposalData) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-[#e6e2dd] font-gilroyBold text-3xl">
        Hang tight! We're crafting your proposal...
      </div>
    );
  }

  return (
    <>
      <ScrollToTop />
      <div className="bg-[#e6e2dd] pt-12 min-h-screen font-gilroyRegular">
        {/* Navbar */}
        <div className="flex flex-row items-center justify-between px-4 md:px-16 lg:px-32 xl:px-48">
          <Link to={"/"}>
            <img
              alt="axillio-logo"
              src="/images/axillio-logo.png"
              className="w-full h-auto"
            />
          </Link>
        </div>

        {/* Section One */}
        <div className="flex flex-col items-center justify-center px-8 pt-20 space-y-12 md:px-16 lg:px-24 xl:px-48 md:pt-28 ">
          <div className="flex flex-col items-center justify-center space-y-4 md:space-y-8">
            <h1 className="text-[#303030] text-4xl md:text-5xl text-center font-gilroyBold">
              {proposalData.Title} {/* Render the title dynamically */}
            </h1>
            {/* <h1 className="text-[#303030] text-lg md:text-xl font-semibold">
              for [Client/Project Name]
            </h1> */}
          </div>

          <div className="flex flex-row justify-between items-center border-b border-[#8b8986] w-full">
            <h1 className="text-[#303030] text-lg md:text-xl font-semibold">
              Prepared by Axillio
            </h1>
            <h1 className="text-[#303030] text-lg md:text-xl font-semibold">
              {new Date().toLocaleDateString()}
            </h1>
          </div>
          <div className="flex items-end justify-end w-full">
            <button
              onClick={() => setIsEditMode((prev) => !prev)}
              className="text-2xl text-[#303030] hover:text-[#404040] mb-4"
            >
              {isEditMode ? <FiCheck /> : <FiEdit3 />}
            </button>
          </div>

          {/* Areas of Consideration */}
          <div className="flex flex-col items-center justify-center px-4 pt-12 space-y-8 md:space-y-16 md:px-0">
            <h1 className="text-[#303030] text-2xl sm:text-3xl md:text-4xl text-center font-gilroyBold">
              Areas of Consideration
            </h1>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
              {/* Dynamically render areas of consideration */}
              {proposalData.areasOfConsideration.map((area, index) => (
                <div
                  key={index}
                  className="border border-[#8b8986] py-6 pl-6 pr-10 flex flex-row items-center space-x-10"
                >
                  <h1 className="text-[#e6e2dd] text-lg md:text-xl text-left font-semibold bg-[#303030] px-4 py-2">
                    {index + 1}.
                  </h1>
                  <h1 className="text-[#303030] text-lg md:text-2xl text-left font-semibold">
                    {area.charAt(0).toUpperCase() + area.slice(1).toLowerCase()}
                  </h1>
                </div>
              ))}
            </div>
          </div>
          <VideoSection
            proposalData={proposalData}
            id={id}
            isEditMode={isEditMode}
          />
          {/* Related Experiences */}
          <div className="flex flex-col items-center justify-center w-full px-4 pt-24 space-y-8 md:space-y-16 md:px-0">
            <h1 className="text-[#303030] text-2xl sm:text-3xl md:text-4xl text-center font-gilroyBold">
              Related Experiences
            </h1>
            <div className="grid w-full grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
              {/* Dynamically render related experiences */}
              {proposalData.relatedExperiences.map((experience, index) => (
                <div
                  key={index}
                  className="border border-[#8b8986] flex flex-col space-y-4 p-6 w-full h-auto overflow-wrap break-words max-w-full"
                >
                  <div className="flex flex-col space-y-2 pb-2 border-b border-[#8b8986]">
                    <h1 className="text-[#8b8986] text-md md:text-lg text-left">
                      Problem
                    </h1>
                    <h1 className="text-[#303030] text-2xl md:text-3xl text-left font-gilroyBold px-3">
                      {experience.problem.charAt(0).toUpperCase() +
                        experience.problem.slice(1)}
                    </h1>
                  </div>
                  <div className="flex flex-col pb-2 space-y-2">
                    <h1 className="text-[#8b8986] text-md md:text-lg text-left">
                      Solution
                    </h1>
                    <div className="flex flex-row items-center py-2 pl-1 space-x-2">
                      <img
                        alt="logo"
                        src={experience.projectImage}
                        className="w-auto h-6"
                      />{" "}
                      {/* <h1 className="text-[#303030] text-lg font-gilroyBold md:text-xl text-left">
                        {experience.projectSource}
                      </h1> */}
                    </div>
                    <h1 className="text-[#676665] text-lg md:text-xl text-left">
                      {experience.solution}
                    </h1>
                  </div>
                  <div className="flex flex-col pb-2 space-y-2">
                    <h1 className="text-[#303030] text-md md:text-lg text-left font-semibold">
                      Technologies/ Tools:
                    </h1>
                    <div className="relative flex flex-row items-center space-x-2">
                      {experience.technologies.map((tech, techIndex) => (
                        <img
                          key={techIndex}
                          src={tech.iconLink}
                          className="w-10 h-auto"
                          data-tooltip-id={`tooltip-${techIndex}`} // Unique ID for each tooltip
                          data-tooltip-content={tech.technology} // Tooltip text
                        />
                      ))}
                      {experience.technologies.map((tech, techIndex) => (
                        <Tooltip
                          id={`tooltip-${techIndex}`} // Corresponding tooltip ID
                          place="top"
                          style={{
                            color: "#e6e2dd",
                            backgroundColor: "#303030",
                          }}
                          effect="solid"
                        />
                      ))}
                    </div>
                  </div>
                  <div className="flex flex-col pb-2 space-y-4">
                    <h1 className="text-[#303030] text-md md:text-lg text-left font-semibold">
                      Problem solved:
                    </h1>
                    <h1 className="text-[#676665] text-lg md:text-xl text-left">
                      {experience.problemSolved}
                    </h1>
                  </div>
                  <div className="flex flex-col pb-2 space-y-4">
                    <h1 className="text-[#303030] text-md md:text-lg text-left font-semibold">
                      Results:
                    </h1>
                    <h1 className="text-[#676665] text-lg md:text-xl text-left">
                      {experience.results}
                    </h1>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* footer */}
        <div className="bg-[#1a1a1a] flex flex-col space-y-16 md:space-y-28 pb-12 pt-12 md:pt-24 mt-32 md:mt-64 px-8 md:px-16 lg:px-32 xl:px-48">
          <div className="flex flex-col items-center justify-between space-y-8 md:space-y-12">
            <h1 className="text-sm md:text-md text-[#d6d2cd]">Follow us on:</h1>
            <div className="flex flex-row items-center justify-center space-x-4 md:space-x-8">
              <a
                href="https://www.behance.net/axillio"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt="behance"
                  src="/images/behance_logo.png"
                  className="w-8 h-auto md:w-12 lg:w-full"
                />
              </a>
              <a
                href="https://x.com/AliMukhtar081"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt="x"
                  src="/images/x_logo.png"
                  className="w-8 h-auto md:w-12 lg:w-full"
                />
              </a>
              <a
                href="https://dribbble.com/axillio"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt="football"
                  src="/images/footbal-logo.png"
                  className="w-8 h-auto md:w-12 lg:w-full"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/axillio/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt="linkedin"
                  src="/images/linkedin_symbol.png"
                  className="w-8 h-auto md:w-12 lg:w-full"
                />
              </a>
              <a
                href="https://www.instagram.com/axillioofficial/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt="insta"
                  src="/images/insta-logo.png"
                  className="w-8 h-auto md:w-12 lg:w-full"
                />
              </a>
              <a
                href="https://www.youtube.com/@Axillio"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt="youtube"
                  src="/images/youtube-logo.png"
                  className="w-8 h-auto md:w-12 lg:w-full"
                />
              </a>
            </div>

            <h1 className="text-sm md:text-md text-[#d6d2cd]">
              © 2024 Axillio. All Rights Reserved
            </h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default Proposal;
