import { Link } from "react-router-dom";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { GoArrowRight } from "react-icons/go";
import ScrollToTop from "../../components/ScrollToTop";
import { Helmet } from "react-helmet";
import ClipLoader from "react-spinners/ClipLoader"; // Import the spinner
import LazyImage from "../../components/LazyImage";
const Roam = () => {
  const [loading, setLoading] = useState(true);

  // Optionally set loading to false if all images use LazyImage
  useEffect(() => {
    setLoading(false);
  }, []);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-[#e6e2dd]">
        <ClipLoader size={50} color={"#8b8986"} loading={loading} />
      </div>
    );
  }
  return (
    <>
      <Helmet>
        <title>Roam - Simplifying Property Listings for Digital Nomads</title>
        <meta
          name="description"
          content="Roam is an all-in-one platform for Digital Nomads, simplifying property showcasing, listings management, and transactions for short-term rentals."
        />
        <meta
          name="keywords"
          content="Roam, digital nomads, property listings, short-term rentals, property management, travel accommodation"
        />
      </Helmet>

      <ScrollToTop />

      <div className="bg-[#e6e2dd] pt-12 min-h-screen font-gilroyRegular">
        {/* navbar */}
        <div className="flex flex-row items-center justify-between px-8 md:px-16 lg:px-24 xl:px-48">
          <Link to={"/"}>
            <LazyImage
              alt="axillio-logo"
              src="/images/axillio-logo.png"
              className="w-full h-auto"
            />
          </Link>
          <div className="flex flex-row items-center space-x-1 hover:cursor-pointer">
            <h1 className="text-[#303030] text-base md:text-xl font-semibold">
              Start a Project
            </h1>
            <GoArrowRight className="text-[#303030] text-base md:text-xl" />
          </div>
        </div>
        {/* section one */}
        <div className="flex flex-col items-center justify-center pt-20 space-y-16 md:space-y-20 md:pt-40 lg:pt-80">
          <div className="flex flex-col items-center justify-center space-y-4 md:space-y-6">
            <h1 className="text-[#303030] text-4xl md:text-6xl text-center font-gilroyBold">
              Roam
            </h1>
            <h1 className="text-[#303030] text-lg md:text-xl text-center font-semibold">
              "Crafting a Unified Brand and Digital Ecosystem for Vacation
              Rentals"{" "}
            </h1>
          </div>

          <div className="flex flex-col items-center justify-center w-full space-y-6">
            <LazyImage
              alt="roam"
              src="/images/roam/roam-1.png"
              className="w-full h-auto"
            />
          </div>

          <div className="flex flex-col items-center justify-center pt-10 space-y-4 md:space-y-16">
            <h1 className="text-[#303030] text-3xl sm:text-4xl md:text-5xl text-center font-semibold">
              The Challenge
            </h1>
            <h1 className="text-[#303030] text-base sm:text-lg md:text-xl text-center font-semibold w-full max-w-[90%] sm:max-w-[75%] md:max-w-[60%] lg:max-w-[55vh]">
              Roam is a vacation rental platform tailored for digital nomads,
              offering seamless bookings and services for both guests and hosts
              wherever they’re currently staying. We collaborated with Roam to
              build their entire brand identity, digital presence, and internal
              tools.
            </h1>
          </div>

          <div className="flex flex-col items-center justify-center px-4 pt-12 space-y-8 md:space-y-16 md:px-0">
            <h1 className="text-[#303030] text-3xl sm:text-4xl md:text-5xl text-center font-semibold">
              Problems
            </h1>
            <div className="flex flex-col space-y-4">
              <div className="flex flex-col items-center justify-center space-x-0 space-y-4 md:flex-row md:space-x-4 md:space-y-0">
                <div className="border border-[#8b8986] w-full md:w-[40vh] py-10 px-5 space-y-8">
                  <h1 className="text-[#303030] text-lg md:text-xl text-left font-semibold px-2">
                    1.
                  </h1>
                  <h1 className="text-[#303030] text-lg md:text-xl text-left font-semibold">
                    Inconsistent Brand Identity Across Touchpoints
                  </h1>
                </div>
                <div className="border border-[#8b8986] w-full md:w-[40vh] py-10 px-5 space-y-8">
                  <h1 className="text-[#303030] text-lg md:text-xl text-left font-semibold px-2">
                    2.
                  </h1>
                  <h1 className="text-[#303030] text-lg md:text-xl text-left font-semibold">
                    Fragmented User Experience Across Different Stakeholders
                  </h1>
                </div>
              </div>
              <div className="flex flex-col items-center justify-center space-x-0 space-y-4 md:flex-row md:space-x-4 md:space-y-0">
                <div className="border border-[#8b8986] w-full md:w-[40vh] py-10 px-5 space-y-8">
                  <h1 className="text-[#303030] text-lg md:text-xl text-left font-semibold px-2">
                    3.
                  </h1>
                  <h1 className="text-[#303030] text-lg md:text-xl text-left font-semibold">
                    Complex Dashboard Management for VAs
                  </h1>
                </div>
                <div className="border border-[#8b8986] w-full md:w-[40vh] py-10 px-5 space-y-8">
                  <h1 className="text-[#303030] text-lg md:text-xl text-left font-semibold px-2">
                    4.
                  </h1>
                  <h1 className="text-[#303030] text-lg md:text-xl text-left font-semibold">
                    Lack of Social Media Presence and Growth
                  </h1>
                </div>
              </div>
              <div className="flex flex-col items-start justify-start space-x-0 space-y-4 md:flex-row md:space-x-4 md:space-y-0">
                <div className="border border-[#8b8986] w-full md:w-[40vh] py-10 px-5 space-y-8">
                  <h1 className="text-[#303030] text-lg md:text-xl text-left font-semibold px-2">
                    5.
                  </h1>
                  <h1 className="text-[#303030] text-lg md:text-xl text-left font-semibold">
                    Challenging Launch and Growth Strategy
                  </h1>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center justify-center px-8 pt-12 space-y-4 md:space-y-16 md:px-0">
            <h1 className="text-[#303030] text-3xl sm:text-4xl md:text-5xl text-center font-semibold">
              What we solved
            </h1>
            <div className="flex flex-col space-y-4">
              <div className="flex flex-col items-center justify-center space-y-4 md:flex-row md:space-y-0 md:space-x-4">
                <div className="border border-[#8b8986] w-full md:w-[40vh] py-10 px-5 space-y-8 h-full min-h-[40vh]">
                  <h1 className="text-[#303030] text-lg md:text-xl text-left font-semibold px-2">
                    1.
                  </h1>
                  <h1 className="text-[#303030] text-lg md:text-xl text-left font-semibold">
                    We developed a comprehensive design system that included a
                    modern, nomad-friendly logo, brand guidelines, typography,
                    and color palette that reflected the adventurous and
                    flexible spirit of their users.
                  </h1>
                </div>
                <div className="border border-[#8b8986] w-full md:w-[40vh] py-10 px-5 space-y-8 h-full min-h-[40vh]">
                  <h1 className="text-[#303030] text-lg md:text-xl text-left font-semibold px-2">
                    2.
                  </h1>
                  <h1 className="text-[#303030] text-lg md:text-xl text-left font-semibold">
                    We designed and developed fully functional mobile apps for
                    guests, hosts, and VAs with custom features tailored to each
                    group.
                  </h1>
                </div>
              </div>
              <div className="flex flex-col items-center justify-center space-y-4 md:flex-row md:space-y-0 md:space-x-4">
                <div className="border border-[#8b8986] w-full md:w-[40vh] py-10 px-5 space-y-8 h-full min-h-[40vh]">
                  <h1 className="text-[#303030] text-lg md:text-xl text-left font-semibold px-2">
                    3.
                  </h1>
                  <h1 className="text-[#303030] text-lg md:text-xl text-left font-semibold">
                    We developed a custom VA dashboard that simplified property
                    management, client communication, and scheduling.
                  </h1>
                </div>
                <div className="border border-[#8b8986] w-full md:w-[40vh] py-10 px-5 space-y-8 h-full min-h-[40vh]">
                  <h1 className="text-[#303030] text-lg md:text-xl text-left font-semibold px-2">
                    4.
                  </h1>
                  <h1 className="text-[#303030] text-lg md:text-xl text-left font-semibold">
                    We created a suite of engaging Instagram reels, cover art,
                    and social media assets that resonated with the digital
                    nomad community.
                  </h1>
                </div>
              </div>
              <div className="flex flex-col items-start justify-start space-y-4 md:flex-row md:space-y-0 md:space-x-4">
                <div className="border border-[#8b8986] w-full md:w-[40vh] py-10 px-5 space-y-8 h-full min-h-[40vh]">
                  <h1 className="text-[#303030] text-lg md:text-xl text-left font-semibold px-2">
                    5.
                  </h1>
                  <h1 className="text-[#303030] text-lg md:text-xl text-left font-semibold">
                    We provided launch strategy support, including growth
                    hacking tactics, email marketing campaigns, and optimized
                    landing pages.
                  </h1>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center justify-center pt-12 space-y-4 md:space-y-16">
            <h1 className="text-[#303030] text-3xl sm:text-4xl md:text-5xl text-center font-semibold">
              Key Impact Numbers
            </h1>
            <div className="flex flex-col space-y-4">
              <div className="flex flex-col items-center justify-center space-y-4 md:flex-row md:space-y-0 md:space-x-4">
                <div className="border border-[#8b8986] w-full md:w-[30vh] py-10 px-5 space-y-8">
                  <h1 className="text-[#303030] text-3xl md:text-5xl text-center font-gilroyBold px-2">
                    30%
                  </h1>
                  <h1 className="text-[#303030] text-lg sm:text-xl md:text-2xl text-center font-semibold">
                    increase in customer engagement
                  </h1>
                </div>
                <div className="border border-[#8b8986] w-full md:w-[30vh] py-10 px-5 space-y-8">
                  <h1 className="text-[#303030] text-3xl md:text-5xl text-center font-gilroyBold px-2">
                    50k
                  </h1>
                  <h1 className="text-[#303030] text-lg sm:text-xl md:text-2xl text-center font-semibold">
                    optimized daily active users
                  </h1>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center justify-center pt-12 space-y-4 md:space-y-16">
            <h1 className="text-[#303030] text-3xl sm:text-4xl md:text-5xl text-center font-semibold">
              Tools
            </h1>
            <div className="flex flex-col space-y-10">
              <div className="flex flex-col items-center justify-center space-y-6">
                <h1 className="text-[#303030] text-lg sm:text-xl md:text-2xl text-center font-semibold">
                  Design
                </h1>
                <div className="flex flex-col items-center justify-center space-y-6 sm:flex-row sm:space-y-0 sm:space-x-6">
                  <LazyImage
                    alt="figma"
                    src="/images/figma.png"
                    className="w-full h-auto "
                  />
                  <LazyImage
                    alt="ai"
                    src="/images/ai.png"
                    className="w-full h-auto "
                  />
                  <LazyImage
                    alt="ae"
                    src="/images/ae.png"
                    className="w-full h-auto "
                  />
                </div>
              </div>

              <div className="flex flex-col items-center justify-center space-y-6">
                <h1 className="text-[#303030] text-lg sm:text-xl md:text-2xl text-center font-semibold">
                  Development
                </h1>
                <div className="flex flex-col items-center justify-center space-y-6 sm:flex-row sm:space-y-0 sm:space-x-6">
                  <LazyImage
                    alt="react"
                    src="/images/react.png"
                    className="w-full h-auto "
                  />
                  <LazyImage
                    alt="js"
                    src="/images/js.png"
                    className="w-full h-auto "
                  />
                  <LazyImage
                    alt="stripe"
                    src="/images/stripe.png"
                    className="w-full h-auto "
                  />
                </div>
              </div>

              <div className="flex flex-col items-center justify-center space-y-6">
                <h1 className="text-[#303030] text-lg sm:text-xl md:text-2xl text-center font-semibold">
                  Marketing & Launch
                </h1>
                <div className="flex flex-col items-center justify-center space-y-6 sm:flex-row sm:space-y-0 sm:space-x-6">
                  <LazyImage
                    alt="monkey"
                    src="/images/monkey.png"
                    className="w-full h-auto "
                  />
                  <LazyImage
                    alt="bars"
                    src="/images/bars.png"
                    className="w-full h-auto "
                  />
                  <LazyImage
                    alt="insta"
                    src="/images/insta.png"
                    className="w-full h-auto "
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center justify-center w-full pt-12 space-y-16 md:space-y-24">
            <h1 className="text-[#303030] text-3xl sm:text-4xl md:text-5xl text-center font-semibold">
              Process
            </h1>
            <div className="flex flex-col items-center justify-center w-full space-y-8">
              <h1 className="text-[#303030] text-lg sm:text-xl md:text-2xl text-center font-semibold">
                1. Discovery & Research
              </h1>
              <h1 className="text-[#303030] text-base sm:text-lg md:text-xl text-center font-semibold w-full max-w-[90%] sm:max-w-[75%] md:max-w-[55vh]">
                Roam is a vacation rental platform tailored for digital nomads,
                offering seamless bookings and services for both guests and
                hosts wherever they’re currently staying. We collaborated with
                Roam to build their entire brand identity, digital presence, and
                internal tools.
              </h1>

              <LazyImage
                alt="roam"
                src="/images/roam/roam-2.png"
                className="w-full h-auto"
              />
            </div>

            <div className="flex flex-col items-center justify-center w-full space-y-8">
              <h1 className="text-[#303030] text-lg sm:text-xl md:text-2xl text-center font-semibold">
                2. Brand Design{" "}
              </h1>
              <h1 className="text-[#303030] text-base sm:text-lg md:text-xl text-center font-semibold w-full max-w-[90%] sm:max-w-[75%] md:max-w-[55vh]">
                Roam is a vacation rental platform tailored for digital nomads,
                offering seamless bookings and services for both guests and
                hosts wherever they’re currently staying. We collaborated with
                Roam to build their entire brand identity, digital presence, and
                internal tools.
              </h1>

              <LazyImage
                alt="roam"
                src="/images/roam/roam-3.png"
                className="w-full h-auto"
              />
              <div className="flex flex-col w-full space-y-8 md:flex-row md:space-y-0 md:space-x-8">
                <div className="flex flex-col w-full space-y-8">
                  <LazyImage
                    alt="roam"
                    src="/images/roam/roam-4.png"
                    className="w-full h-auto"
                  />
                  <LazyImage
                    alt="roam"
                    src="/images/roam/roam-6.png"
                    className="w-full h-auto"
                  />
                  <LazyImage
                    alt="roam"
                    src="/images/roam/roam-8.png"
                    className="w-full h-auto"
                  />
                  <LazyImage
                    alt="roam"
                    src="/images/roam/roam-11.png"
                    className="w-full h-auto"
                  />
                </div>

                <div className="flex flex-col w-full space-y-8">
                  <LazyImage
                    alt="roam"
                    src="/images/roam/roam-5.png"
                    className="w-full h-auto"
                  />
                  <LazyImage
                    alt="roam"
                    src="/images/roam/roam-7.png"
                    className="w-full h-auto"
                  />
                  <LazyImage
                    alt="roam"
                    src="/images/roam/roam-9.png"
                    className="w-full h-auto"
                  />
                  <LazyImage
                    alt="roam"
                    src="/images/roam/roam-10.png"
                    className="w-full h-auto"
                  />
                  <LazyImage
                    alt="roam"
                    src="/images/roam/roam-12.png"
                    className="w-full h-auto"
                  />
                </div>
              </div>

              <div className="flex items-center justify-center">
                <LazyImage
                  alt="roam"
                  src="/images/roam/roam-13.png"
                  className="w-full h-auto"
                />
              </div>
            </div>
            <div className="flex flex-col items-center justify-center w-full space-y-8">
              <h1 className="text-[#303030] text-lg sm:text-xl md:text-2xl text-center font-semibold">
                3. Mobile App Development
              </h1>
              <h1 className="text-[#303030] text-base sm:text-lg md:text-xl text-center font-semibold w-full max-w-[90%] sm:max-w-[75%] md:max-w-[55vh]">
                Roam is a vacation rental platform tailored for digital nomads,
                offering seamless bookings and services for both guests and
                hosts wherever they’re currently staying. We collaborated with
                Roam to build their entire brand identity, digital presence, and
                internal tools.
              </h1>
              <div className="flex flex-col w-full space-y-8">
                <div className="flex flex-col w-full space-y-8 md:flex-row md:space-y-0 md:space-x-8">
                  <LazyImage
                    alt="roam"
                    src="/images/roam/roam-14.png"
                    className="w-full h-auto"
                  />
                  <LazyImage
                    alt="roam"
                    src="/images/roam/roam-15.png"
                    className="w-full h-auto"
                  />
                </div>

                <div className="w-full">
                  <LazyImage
                    alt="roam"
                    src="/images/roam/roam-16.png"
                    className="w-full h-auto"
                  />
                </div>

                <div className="flex flex-col w-full space-y-8 md:flex-row md:space-y-0 md:space-x-8">
                  <LazyImage
                    alt="roam"
                    src="/images/roam/roam-17.png"
                    className="w-full h-auto"
                  />
                  <LazyImage
                    alt="roam"
                    src="/images/roam/roam-18.png"
                    className="w-full h-auto"
                  />
                </div>

                <div className="flex flex-col w-full space-y-8 md:flex-row md:space-y-0 md:space-x-8">
                  <LazyImage
                    alt="roam"
                    src="/images/roam/roam-19.png"
                    className="w-full h-auto"
                  />
                  <LazyImage
                    alt="roam"
                    src="/images/roam/roam-20.png"
                    className="w-full h-auto"
                  />
                </div>

                <div className="w-full">
                  <LazyImage
                    alt="roam"
                    src="/images/roam/roam-21.png"
                    className="w-full h-auto"
                  />
                </div>

                <div className="flex flex-col w-full space-y-8 md:flex-row md:space-y-0 md:space-x-8">
                  <LazyImage
                    alt="roam"
                    src="/images/roam/roam-22.png"
                    className="w-full h-auto"
                  />
                  <LazyImage
                    alt="roam"
                    src="/images/roam/roam-23.png"
                    className="w-full h-auto"
                  />
                </div>

                <div className="w-full">
                  <LazyImage
                    alt="roam"
                    src="/images/roam/roam-24.png"
                    className="w-full h-auto"
                  />
                </div>

                <div className="w-full">
                  <LazyImage
                    alt="roam"
                    src="/images/roam/roam-25.png"
                    className="w-full h-auto"
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center justify-center w-full space-y-8">
              <h1 className="text-[#303030] text-lg sm:text-xl md:text-2xl text-center font-semibold">
                4. Dashboard Development
              </h1>
              <h1 className="text-[#303030] text-base sm:text-lg md:text-xl text-center font-semibold w-full max-w-[90%] sm:max-w-[75%] md:max-w-[55vh]">
                Roam is a vacation rental platform tailored for digital nomads,
                offering seamless bookings and services for both guests and
                hosts wherever they’re currently staying. We collaborated with
                Roam to build their entire brand identity, digital presence, and
                internal tools.
              </h1>

              <div className="flex flex-col w-full space-y-8">
                <div className="w-full">
                  <LazyImage
                    alt="roam"
                    src="/images/roam/roam-26.png"
                    className="w-full h-auto"
                  />
                </div>

                <div className="flex flex-col w-full space-y-8 md:flex-row md:space-y-0 md:space-x-8">
                  <LazyImage
                    alt="roam"
                    src="/images/roam/roam-27.png"
                    className="w-full h-auto"
                  />
                  <LazyImage
                    alt="roam"
                    src="/images/roam/roam-28.png"
                    className="w-full h-auto"
                  />
                </div>

                <div className="flex flex-col w-full space-y-8 md:flex-row md:space-y-0 md:space-x-8">
                  <LazyImage
                    alt="roam"
                    src="/images/roam/roam-29.png"
                    className="w-full h-auto"
                  />
                  <LazyImage
                    alt="roam"
                    src="/images/roam/roam-30.png"
                    className="w-full h-auto"
                  />
                </div>

                <div className="w-full">
                  <LazyImage
                    alt="roam"
                    src="/images/roam/roam-31.png"
                    className="w-full h-auto"
                  />
                </div>

                <div className="w-full">
                  <LazyImage
                    alt="roam"
                    src="/images/roam/roam-32.png"
                    className="w-full h-auto"
                  />
                </div>

                <div className="w-full">
                  <LazyImage
                    alt="roam"
                    src="/images/roam/roam-33.png"
                    className="w-full h-auto"
                  />
                </div>

                <div className="flex flex-col w-full space-y-8 md:flex-row md:space-y-0 md:space-x-8">
                  <div className="flex flex-col w-full space-y-8">
                    <LazyImage
                      alt="roam"
                      src="/images/roam/roam-36.png"
                      className="w-full h-auto"
                    />
                    <LazyImage
                      alt="roam"
                      src="/images/roam/roam-35.png"
                      className="w-full h-auto"
                    />
                  </div>
                  <div className="flex flex-col w-full space-y-8">
                    <LazyImage
                      alt="roam"
                      src="/images/roam/roam-34.png"
                      className="w-full h-auto"
                    />
                    <LazyImage
                      alt="roam"
                      src="/images/roam/roam-37.png"
                      className="w-full h-auto"
                    />
                  </div>
                </div>

                <div className="w-full">
                  <LazyImage
                    alt="roam"
                    src="/images/roam/roam-38.png"
                    className="w-full h-auto"
                  />
                </div>

                <div className="w-full">
                  <LazyImage
                    alt="roam"
                    src="/images/roam/roam-39.png"
                    className="w-full h-auto"
                  />
                </div>

                <div className="w-full">
                  <LazyImage
                    alt="roam"
                    src="/images/roam/roam-40.png"
                    className="w-full h-auto"
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center justify-center w-full space-y-8">
              <h1 className="text-[#303030] text-lg sm:text-xl md:text-2xl text-center font-semibold">
                5. Social Media Strategy
              </h1>
              <h1 className="text-[#303030] text-base sm:text-lg md:text-xl text-center font-semibold w-full max-w-[90%] sm:max-w-[75%] md:max-w-[55vh]">
                Roam is a vacation rental platform tailored for digital nomads,
                offering seamless bookings and services for both guests and
                hosts wherever they’re currently staying. We collaborated with
                Roam to build their entire brand identity, digital presence, and
                internal tools.
              </h1>

              <div className="flex flex-col w-full space-y-8">
                <div className="flex flex-row w-full space-x-8">
                  <LazyImage
                    alt="roam"
                    src="/images/roam/roam-41.png"
                    className="w-full h-auto"
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center justify-center w-full space-y-8">
              <h1 className="text-[#303030] text-xl sm:text-2xl md:text-3xl text-center font-semibold">
                6. Launch & Growth
              </h1>
              <h1 className="text-[#303030] text-base sm:text-lg md:text-xl text-center font-semibold w-full max-w-[90%] sm:max-w-[75%] md:max-w-[55vh]">
                Roam is a vacation rental platform tailored for digital nomads,
                offering seamless bookings and services for both guests and
                hosts wherever they’re currently staying. We collaborated with
                Roam to build their entire brand identity, digital presence, and
                internal tools.
              </h1>

              <div className="flex flex-col items-center justify-center pt-16 space-y-8 md:space-y-16">
                <h1 className="text-[#303030] text-3xl sm:text-4xl md:text-5xl text-center font-semibold">
                  Key Impact Numbers
                </h1>
                <div className="flex flex-col space-y-4">
                  <div className="flex flex-col items-center justify-center space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4">
                    <div className="border border-[#8b8986] w-full sm:w-[30vh] py-10 px-5 space-y-8">
                      <h1 className="text-[#303030] text-3xl sm:text-4xl md:text-5xl text-center font-gilroyBold px-2">
                        30%
                      </h1>
                      <h1 className="text-[#303030] text-lg sm:text-xl md:text-2xl text-center font-semibold">
                        increase in customer engagement
                      </h1>
                    </div>
                    <div className="border border-[#8b8986] w-full sm:w-[30vh] py-10 px-5 space-y-8">
                      <h1 className="text-[#303030] text-3xl sm:text-4xl md:text-5xl text-center font-gilroyBold px-2">
                        50k
                      </h1>
                      <h1 className="text-[#303030] text-lg sm:text-xl md:text-2xl text-center font-semibold">
                        optimized daily active users
                      </h1>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex flex-col items-center justify-center w-full pt-20 space-y-8">
                <h1 className="text-[#303030] text-3xl sm:text-4xl md:text-5xl text-center font-semibold">
                  Roam Founder’s Review
                </h1>
                <h1 className="text-[#303030] text-base sm:text-lg md:text-xl text-center font-semibold w-full max-w-[90%] sm:max-w-[75%] md:max-w-[55vh]">
                  Roam is a vacation rental platform tailored for digital
                  nomads, offering seamless bookings and services for both
                  guests and hosts wherever they’re currently staying. We
                  collaborated with Roam to build their entire brand identity,
                  digital presence, and internal tools.
                </h1>
              </div>
            </div>
          </div>
        </div>
        {/* footer */}
        <div className="bg-[#1a1a1a] flex flex-col space-y-16 md:space-y-28 pb-12 pt-12 md:pt-24 mt-32 md:mt-64 px-8 md:px-16 lg:px-32 xl:px-48">
          <div className="flex flex-col justify-between space-y-12 md:flex-row md:space-y-0">
            <div className="flex flex-col space-y-6">
              <h1 className="text-[#e6e2dd] text-lg md:text-2xl font-semibold">
                AXILLIO
              </h1>
              <div className="flex flex-col">
                <h1 className="text-sm md:text-md text-[#d6d2cd]">
                  San Francisco
                </h1>
                <h1 className="text-sm md:text-md text-[#d6d2cd]">
                  Delaware UAE
                </h1>
              </div>
              <h1 className="text-sm md:text-md text-[#d6d2cd]">
                example@axillio.com
              </h1>
            </div>
            <div className="flex flex-col space-y-4">
              <h1 className="text-sm md:text-md text-[#d6d2cd]">Portfolio</h1>
              <h1 className="text-sm md:text-md text-[#d6d2cd]">Services</h1>
              <h1 className="text-sm md:text-md text-[#d6d2cd]">About</h1>
            </div>
            <div className="flex flex-col space-y-6">
              <div className="flex flex-row items-center space-x-1 hover:cursor-pointer">
                <h1 className="text-[#e6e2dd] text-lg font-semibold">
                  Start a Project
                </h1>
                <GoArrowRight className="text-[#e6e2dd] text-lg md:text-xl" />
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center justify-between space-y-8 md:space-y-12">
            <h1 className="text-sm md:text-md text-[#d6d2cd]">Follow us on:</h1>
            <div className="flex flex-row items-center justify-center space-x-4 md:space-x-8">
              <a
                href="https://www.behance.net"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LazyImage
                  alt="behance"
                  src="./images/behance_logo.png"
                  className="w-8 h-auto md:w-12 lg:w-full"
                />
              </a>
              <a
                href="https://x.com/AliMukhtar081"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LazyImage
                  alt="x"
                  src="./images/x_logo.png"
                  className="w-8 h-auto md:w-12 lg:w-full"
                />
              </a>
              <a
                href="https://dribbble.com/axillio"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LazyImage
                  alt="football"
                  src="./images/footbal-logo.png"
                  className="w-8 h-auto md:w-12 lg:w-full"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/axillio/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LazyImage
                  alt="linkedin"
                  src="./images/linkedin_symbol.png"
                  className="w-8 h-auto md:w-12 lg:w-full"
                />
              </a>
              <a
                href="https://www.instagram.com/axillioofficial/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LazyImage
                  alt="insta"
                  src="./images/insta-logo.png"
                  className="w-8 h-auto md:w-12 lg:w-full"
                />
              </a>
              <a
                href="https://www.youtube.com/@Axillio"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LazyImage
                  alt="youtube"
                  src="./images/youtube-logo.png"
                  className="w-8 h-auto md:w-12 lg:w-full"
                />
              </a>
            </div>

            <h1 className="text-sm md:text-md text-[#d6d2cd]">
              © 2024 Axillio. All Rights Reserved
            </h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default Roam;
